import { Grid } from "@mui/material";
import { SteppedForm, formDef } from "../SteppedForm";

export const vendorForm: formDef = {
  title: "Create Vendor",
  instructions:
    "Let's get started with some basic information about your service, which will be shown on our 'Vendor detail' page. Once that's set up you can add specifics of how customers to book your service. Townish will review and approve your profile before it goes live.",
  formSteps: [
    {
      title: "Description",
      subtitle: "Some initial info about your service.",
      inputs: [
        {
          friendly: "Category",
          field: "vendorCategory",
          type: "mainCategory",
          helpText: "The closest match to the type of service you offer.",
        },
        {
          friendly: "Vendor Name",
          field: "vendorName",
          type: "text",
        },
        {
          friendly: "Vendor Subheading",
          field: "vendorSubheading",
          type: "text",
          helpText: "A short (50 - 100 character) tagline for your service.",
        },
        {
          friendly: "Vendor Description",
          field: "vendorDescription",
          type: "textarea",
          helpText: "A longer description of your service, ideally 350 - 500 characters in length.",
        },
        {
          friendly: "Value Prop (optional)",
          field: "vendorValueProp",
          type: "bulletlist",
          helpText: "A bullet list of 3-6 the main things that customers should know about your service. Enter creates a new bullet point.",
          optional: true,
        },
      ],
    },
    {
      title: "Location",
      subtitle: "Enter the address Townish should use for business purposes. This isn't necessarily the address from which you'll deliver services - we'll configure that later.",
      inputs: [
        {
          friendly: "Street address",
          field: "address",
          type: "text",
        },
        {
          friendly: "City",
          field: "city",
          type: "text",
        },
        {
          friendly: "State / province",
          field: "state",
          type: "text",
        },
        {
          friendly: "ZIP / Postal code",
          field: "zipCode",
          type: "text",
        },
        {
          friendly: "Location",
          field: "vendorLocation",
          type: "location",
        },
      ],
    },
    {
      title: "Contact",
      subtitle: "Let us know how to reach you. Your contact information will not be shared. Booking requests and customer messages will always be sent to you via email.",
      inputs: [
        {
          friendly: "Email address",
          field: "vendorEmail",
          type: "text",
        },
        {
          friendly: "Phone number",
          field: "vendorPhone",
          type: "text",
        },
        {
          friendly: "Website url (optional)",
          field: "vendorURL",
          type: "text",
          optional: true,
        },
      ],
    },
    {
      title: "Media",
      subtitle: "Upload pictures and link to hosted videos that highlight the best parts of your service.",
      inputs: [
        {
          friendly: "Image upload",
          field: "vendorImgPath",
          type: "image",
        },
        {
          friendly: "Link to video (optional)",
          field: "vendorVideoURL",
          type: "text",
          optional: true,
        },
      ],
      // vendorImgPath: string;
      // vendorMainImg: string;
    },
    {
      title: "Finalize",
      subtitle: "Please confirm your information, review and accept the terms of service, and click Create Account to complete this part of the setup.",
      inputs: [
        {
          friendly: "Acceptance of the terms of service",
          field: "vendorTerms",
          type: "checkbox",
        },
      ],
    },
  ],
};

export function CreateVendor() {
  // vendorExperiences: string;
  // amenities: [Amenity] @manyToMany(relationName: "VendorsAmenities")

  return (
    <Grid container direction="row" style={{ padding: "2rem 1.5rem", width: "70%", maxWidth: "800px", margin: "0 auto .25rem auto" }}>
      <SteppedForm form={vendorForm} formType={"vendor"} />
    </Grid>
  );
}
