import { ChangeEvent, useState } from "react";

import { Helmet, HelmetProvider } from "react-helmet-async";

import { Grid, Input } from "@mui/material";
import Slider from "@mui/material/Slider";

import { fromAddress, setKey, setLanguage, setRegion } from "react-geocode";

import { Icon } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Circle, MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";

// Set Google Maps Geocoding API key for quota management (optional but recommended).
// Use this if you want to set the API key independently.
setKey("AIzaSyCV19nLA_lbB_4vBERcTxOGzlqMuEN5zAs"); // Your API key here.

// Set default response language (optional).
// This sets the default language for geocoding responses.
setLanguage("en"); // Default language for responses.

// Set default response region (optional).
// This sets the default region for geocoding responses.
setRegion("es"); // Default region for responses.

export const CustomSliderStyles = {
  "& .MuiSlider-thumb": {
    color: "#749761",
    height: 24,
    width: 24,
  },
  "& .MuiSlider-track": {
    color: "#749761",
    height: 12,
    width: 12,
  },

  "& .MuiSlider-active": {
    color: "#f5e278",
    height: 14,
    width: 14,
  },
};

export interface locationType {
  fullAddress: string;
  shortAddress: string;
  zipCode: string;
  lat: number;
  long: number;
}

export const defaultMapLocation: locationType = {
  fullAddress: "Harrisburg, PA",
  shortAddress: "Harrisburg, PA",
  zipCode: "17112",
  lat: 40.276729002884416,
  long: -76.87740020874793,
};

const eventDistance = [
  { desc: "10km", distance: 10000, zoomLevel: 11 },
  { desc: "25km", distance: 25000, zoomLevel: 10 },
  { desc: "50km", distance: 50000, zoomLevel: 9 },
  { desc: "100km", distance: 100000, zoomLevel: 8 },
  { desc: "anywhere", distance: -1, zoomLevel: 6 },
];

const ChangeView = ({ lat, long, zoomLevel }: { lat: number; long: number; zoomLevel: number }) => {
  const map = useMap();
  map.attributionControl.setPrefix(false);
  map.setView([lat, long], zoomLevel);
  map.setZoom(zoomLevel);
  return null;
};

export default function Map(props: { vendorLocations?: boolean; mapOrigin: locationType; markerLocations?: locationType[]; distanceSliderValue: number; zoomLevelOverride?: number; height?: string }) {
  //let mapLocation: locationType = props.mapOrigin ? props.mapOrigin : defaultMapLocation;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css" integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=" crossOrigin="" />
          <script src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js" integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo=" crossOrigin=""></script>
        </Helmet>
      </HelmetProvider>

      <div className="map">
        <MapContainer center={[props.mapOrigin.lat, props.mapOrigin.long]} zoom={10} scrollWheelZoom={false} style={{ height: props.height ? props.height : "600px", width: "100%" }}>
          <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <ChangeView lat={props.mapOrigin.lat} long={props.mapOrigin.long} zoomLevel={props.zoomLevelOverride ? props.zoomLevelOverride : eventDistance[props.distanceSliderValue].zoomLevel} />
          {eventDistance[props.distanceSliderValue].distance !== -1 && (
            <Circle fillOpacity={0.15} center={[props.mapOrigin.lat, props.mapOrigin.long]} radius={eventDistance[props.distanceSliderValue].distance} />
          )}
          {props.markerLocations &&
            props.markerLocations.map((marker) => {
              return (
                <Marker position={[marker.lat, marker.long]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} key={"ExperienceMarker" + marker.fullAddress}>
                  <Popup key={"ExperiencePopup" + marker.fullAddress}>
                    <span
                      className="desktop-body"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {marker.fullAddress}
                    </span>
                  </Popup>
                </Marker>
              );
            })}
        </MapContainer>
      </div>
    </>
  );
}

export function MapWithControls(props: {
  setMapOrigin: React.Dispatch<React.SetStateAction<locationType>>;
  setSearchRadius: React.Dispatch<React.SetStateAction<number>>;
  mapOrigin: locationType;
  filteredExperiences: any[];
  searchLocation?: string;
}) {
  const [distanceSliderValue, setDistanceSliderValue] = useState(props.searchLocation && props.searchLocation === "any" ? 4 : 3);
  const [originString, setOriginString] = useState(props.searchLocation && props.searchLocation === "any" ? "" : "Toronto, ON");

  const handleSlider = (e: Event) => {
    if (e.target) {
      if ((e.target as HTMLInputElement).name === "distanceSlider") {
        setDistanceSliderValue(Number((e.target as HTMLInputElement).value));
        props.setSearchRadius(eventDistance[Number((e.target as HTMLInputElement).value)].distance);
      }
    }
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setOriginString(value);
  };

  function updateLocationOrigin(origin: string) {
    let newLocation: locationType = {
      fullAddress: originString,
      shortAddress: originString,
      zipCode: "",
      lat: 0,
      long: 0,
    };

    // geolocate the originString
    fromAddress(originString)
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        const { zipCode } = results[0].components.postal_code;

        newLocation.zipCode = zipCode;
        newLocation.lat = lat;
        newLocation.long = lng;

        props.setMapOrigin(newLocation);
      })
      .catch(console.error);
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item my="1rem">
          <Input type="text" name="originString" placeholder="Location" value={originString} onChange={handleInput} />

          <button className="button-small button-transparent" style={{ width: "20%", marginLeft: "1rem" }} onClick={(e) => updateLocationOrigin(originString)}>
            Update
          </button>
        </Grid>
        <Map mapOrigin={props.mapOrigin} distanceSliderValue={distanceSliderValue} />

        <div
          style={{
            width: "80%",
            margin: "2rem auto 0 auto",
          }}
        >
          <Slider aria-label="Distance slider" name="distanceSlider" marks min={0} max={4} step={1} value={distanceSliderValue} onChange={(e) => handleSlider(e)} sx={CustomSliderStyles} />

          <span className="desktop-body" style={{ marginLeft: "1rem" }}>
            {eventDistance[distanceSliderValue].desc}
          </span>
        </div>
      </Grid>
    </>
  );
}
