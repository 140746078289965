import { ReactElement, useState } from "react";

import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import { Bookable, CreateBookableInput } from "../../API";
import { StarIcons } from "../../Utils";

import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";

import { Preview } from "../../pages/preview";
import { Modal } from "@mui/material";
import BookableCard from "../bookableCard";

export function Dashboard(props: { bookables: Bookable[] }) {
  const [previewElement, setPreviewElement] = useState<ReactElement>();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  function handlePreviewModal(bkbl: CreateBookableInput) {
    setPreviewElement(<Preview bookable={bkbl} showEditBtn={true} setModalOpen={setPreviewModalOpen} />);
    setPreviewModalOpen(true);
  }

  return (
    <>
      <Modal open={previewModalOpen} onClose={() => setPreviewModalOpen(false)} style={{ width: "90%", margin: "auto", overflow: "scroll" }}>
        <div className="landing-div" style={{ height: "auto", margin: "1rem", padding: "2rem" }}>
          {previewElement}
        </div>
      </Modal>

      <section className="landing-section">
        <div className="landing-div" style={{ padding: "1rem 10% 1rem 10%" }}>
          <Grid templateColumns="4fr 1fr">
            <h5 style={{ margin: 0 }}>Bookable options</h5>
            <Link to="/pages/createBookable" style={{ textAlign: "right" }}>
              Add booking option
            </Link>
          </Grid>
          <div style={{ marginTop: "1rem" }}>
            {props.bookables.length === 0 && (
              <span className="desktop-body" style={{ color: "red" }}>
                There aren't any options for customers to book your service yet. Add some here.
              </span>
            )}
            {/*props.bookables.length > 0 && (
              <Table caption="" highlightOnHover={false}>
                <TableHead>
                  <TableRow>
                    <TableCell as="th">Bookable option</TableCell>
                    <TableCell as="th">View / Edit</TableCell>
                    <TableCell as="th">Status</TableCell>
                    <TableCell as="th">Views</TableCell>
                    <TableCell as="th">Favorites</TableCell>
                    <TableCell as="th">Customer rating</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.bookables.map((bkbl) => {
                    return (
                      <TableRow key={"tblrow" + bkbl.bookableID}>
                        <TableCell key={"tblcell" + bkbl.bookableID}>
                          <span className="desktop-body" style={{ fontSize: 18 }} key={"spanName" + bkbl.bookableID}>
                            {bkbl.bookableName}
                          </span>
                        </TableCell>
                        <TableCell key={"tblcell2" + bkbl.bookableID}>
                          <Link
                            to={""}
                            onClick={() => {
                              handlePreviewModal(bkbl);
                            }}
                            key={"viewLink" + bkbl.bookableID}
                            style={{ marginRight: "1rem" }}
                          >
                            <VisibilityIcon key={"viewIcon" + bkbl.bookableID} style={{ color: "grey" }} />
                          </Link>
                          <Link to={"/pages/editBookable/" + bkbl.bookableID} key={"editLink" + bkbl.bookableID}>
                            <EditIcon key={"editIcon" + bkbl.bookableID} style={{ color: "grey" }} />
                          </Link>
                        </TableCell>
                        <TableCell key={"tblcell3" + bkbl.bookableID}>
                          <span className="desktop-body" style={{ fontSize: 18 }} key={"spanStatus" + bkbl.bookableID}>
                            Active
                          </span>
                        </TableCell>
                        <TableCell key={"tblcell4" + bkbl.bookableID}>
                          <span className="desktop-body" style={{ fontSize: 18 }} key={"spanViews" + bkbl.bookableID}>
                            0
                          </span>
                        </TableCell>
                        <TableCell key={"tblcell5" + bkbl.bookableID}>
                          <span className="desktop-body" style={{ fontSize: 18 }} key={"spanFavs" + bkbl.bookableID}>
                            0
                          </span>
                        </TableCell>
                        <TableCell key={"tblcell6" + bkbl.bookableID} style={{ paddingLeft: "3rem" }}>
                          <span className="desktop-body" style={{ fontSize: 18 }} key={"spanRating" + bkbl.bookableID}>
                            -
                          </span>
                          {/*<StarIcons rating={4.2} key={"stars" + bkbl.bookableID} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}*/}

            {props.bookables.length > 0 && (
              <Grid templateColumns="1fr 1fr 1fr">
                {props.bookables.map((bkbl) => {
                  return (
                    <div
                      onClick={() => {
                        handlePreviewModal(bkbl);
                      }}
                      key={"bookableDiv" + bkbl.bookableID}
                    >
                      <BookableCard bk={bkbl} key={"bookableCard" + bkbl.bookableID} />
                    </div>
                  );
                })}
              </Grid>
            )}
          </div>
        </div>
      </section>

      <section className="landing-section">
        <div className="landing-div" style={{ padding: "1rem 10% 1rem 10%" }}>
          <Grid templateColumns="4fr 1fr">
            <h5 style={{ margin: 0 }}>Upcoming bookings</h5>

            <span className="desktop-body" style={{ color: "grey", textAlign: "right" }}>
              Coming soon
            </span>
            {/* 
            <Link to="" style={{ textAlign: "right" }}>
              Upcoming events
            </Link>*/}
          </Grid>
          <div style={{ marginTop: ".5rem" }}>
            <span className="desktop-body">There aren't any booked events yet.</span>
          </div>
        </div>
      </section>

      <section className="landing-section">
        <div className="landing-div" style={{ padding: "1rem 10% 1rem 10%" }}>
          <Grid templateColumns="4fr 1fr">
            <h5 style={{ margin: 0 }}>Booking requests</h5>

            <span className="desktop-body" style={{ color: "grey", textAlign: "right" }}>
              Coming soon
            </span>
            {/* 
            <Link to="" style={{ textAlign: "right" }}>
              View requests
            </Link>
            */}
          </Grid>
          <div style={{ marginTop: ".5rem" }}>
            <span className="desktop-body">There aren't any new booking requests at this time.</span>
          </div>
        </div>
      </section>

      <section className="landing-section">
        <div className="landing-div" style={{ padding: "1rem 10% 1rem 10%" }}>
          <Grid templateColumns="4fr 1fr">
            <h5 style={{ margin: 0 }}>Customer messages</h5>

            <span className="desktop-body" style={{ color: "grey", textAlign: "right" }}>
              Coming soon
            </span>
            {/* 
            <Link to="" style={{ textAlign: "right" }}>
              Inbox
            </Link>*/}
          </Grid>
          <div style={{ marginTop: ".5rem" }}>
            <span className="desktop-body">There aren't any new customer messages at this time.</span>
          </div>
        </div>
      </section>
    </>
  );
}
