import { useEffect, useState } from "react";
import { Bookable } from "../../API";

import { generateClient } from "aws-amplify/api";
import { AuthUser, fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { useGlobalContext } from "../../App";
import { fetchBookablesForVendor } from "../../Utils";
import { CreateVendor } from "../../components/CreateVendor";
import { Dashboard } from "../../components/Dashboard";
import { Nav } from "../../components/nav";
import { vendorByOwner } from "../../graphql/queries";

const amplifyClient = generateClient({ authMode: "userPool" });

export function LandingPage() {
  const [newVendor, setNewVendor] = useState(false);
  const [error, setError] = useState<string | null>();
  const [bookables, setBookables] = useState<Bookable[]>([]);

  const { vendor, setVendor } = useGlobalContext();

  // get the vendor id by cognito owner if available

  // if available, enable access to menus, adding bookings, etc.
  // if vendor account for this cognito id explicitly doesn't exist, allow creation of new vendor account
  // if indeterminate, don't allow to proceed

  useEffect(() => {
    async function fetchVendorAccount(user: AuthUser) {
      let auth = await fetchAuthSession();
      let ownerString = auth.userSub + "::" + user.username;
      let response;

      try {
        response = await amplifyClient.graphql({
          query: vendorByOwner,
          variables: {
            owner: ownerString,
          },
        });

        if (response.data.vendorByOwner.items.length === 0) {
          setNewVendor(true);
        } else {
          setVendor(response.data.vendorByOwner.items[0]);
        }
      } catch (err: any) {
        console.log(err);
        setError(err.errors[0]?.message + " - Error in fetchVendorAccount");
      }
    }

    async function findExistingVendor() {
      let user = await getCurrentUser();

      if (user) {
        await fetchVendorAccount(user);
      }
    }

    findExistingVendor();
  }, [setVendor]);

  useEffect(() => {
    if (vendor) {
      setNewVendor(false);
    }
  }, [vendor]);

  useEffect(() => {
    if (vendor && Object.keys(vendor).length >= 0) {
      let newBookables;

      (async () => {
        newBookables = await fetchBookablesForVendor(vendor.vendorID);

        if (newBookables) {
          setBookables(newBookables);
        }
      })();
    }
  }, [vendor]);

  return (
    <>
      {/* LANDING PAGE FOR ALL USERS */}
      <section className="landing-section">
        <div className="landing-div">
          <div>
            <Nav title={newVendor ? "Vendor registration" : "Vendor dashboard"} />
          </div>
        </div>
      </section>

      {/* LOADING MESSAGE */}
      {!newVendor && (!vendor || Object.keys(vendor).length === 0) && !error && (
        <section className="landing-section">
          <div className="landing-div">
            <h5 style={{ margin: 0 }}>Retrieving account details...</h5>
          </div>
        </section>
      )}

      {/* ERROR MESSAGE */}
      {error && (
        <section className="landing-section">
          <div className="landing-div">
            <h5 style={{ margin: 0 }}>There was a problem retrieving your account. Please contact Townish support with the error code: {error}</h5>
          </div>
        </section>
      )}

      {/* USERS WHO HAVE NOT YET CREATED VENDOR RECORD */}
      {newVendor && (!vendor || Object.keys(vendor).length === 0) && (
        <section className="landing-section">
          <div className="landing-div">
            <CreateVendor />
          </div>
        </section>
      )}

      {/* USERS WITH EXISTING VENDOR RECORD */}
      {!newVendor && vendor && Object.keys(vendor).length > 0 && (
        <div className="landing-div">
          <Dashboard bookables={bookables} />
        </div>
      )}
    </>
  );
}
