import { s3ImgParams } from "../../Utils";

// imported from customer-side project and caused folder rename

export default function ImageGallery(props: { imgs: string[]; mainImg: string }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.imgs.map((img) => {
        return (
          <div
            style={{
              margin: ".5rem auto",
              justifyContent: "center",
              width: "100%",
            }}
            key={"div" + img}
          >
            <img src={s3ImgParams.s3ImgPath + encodeURIComponent(img)} key={"img" + img} style={{ objectFit: "cover", maxHeight: "70vh", maxWidth: "100%" }} />
          </div>
        );
      })}
    </div>
  );
}
