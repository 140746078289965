import { Grid } from "@mui/material";
import { vendorForm } from "../../components/CreateVendor";
import { Nav } from "../../components/nav";
import { SteppedForm } from "../../components/SteppedForm";
import { useGlobalContext } from "../../App";

export function EditVendor() {
  let vendor = useGlobalContext().vendor;

  return (
    <>
      <section className="landing-section">
        <div className="landing-div">
          <div>
            <Nav title={"Edit vendor profile"} />
          </div>
        </div>
      </section>

      <div style={{ padding: "2rem 1.5rem" }}>
        <Grid container direction="row" style={{ padding: "2rem 1.5rem", width: "70%", maxWidth: "800px", margin: "0 auto .25rem auto" }}>
          <SteppedForm form={vendorForm} formType={"vendor"} objToEdit={vendor} skipInstructions={true} />
        </Grid>
      </div>
    </>
  );
}
