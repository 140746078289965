/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getMainCategory = /* GraphQL */ `query GetMainCategory($id: ID!) {
  getMainCategory(id: $id) {
    id
    categoryName
    categoryDescription
    categoryImg
    categoryIcon
    categorySequence
    showAsVendors
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMainCategoryQueryVariables,
  APITypes.GetMainCategoryQuery
>;
export const listMainCategories = /* GraphQL */ `query ListMainCategories(
  $id: ID
  $filter: ModelMainCategoryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMainCategories(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      categoryName
      categoryDescription
      categoryImg
      categoryIcon
      categorySequence
      showAsVendors
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMainCategoriesQueryVariables,
  APITypes.ListMainCategoriesQuery
>;
export const getSubCategory = /* GraphQL */ `query GetSubCategory($id: ID!) {
  getSubCategory(id: $id) {
    id
    parentCategoryID
    subCategoryName
    subCategoryImg
    showAsVendors
    bookables {
      items {
        id
        subCategoryId
        bookableBookableID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSubCategoryQueryVariables,
  APITypes.GetSubCategoryQuery
>;
export const listSubCategories = /* GraphQL */ `query ListSubCategories(
  $id: ID
  $filter: ModelSubCategoryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSubCategories(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      parentCategoryID
      subCategoryName
      subCategoryImg
      showAsVendors
      bookables {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSubCategoriesQueryVariables,
  APITypes.ListSubCategoriesQuery
>;
export const getVendor = /* GraphQL */ `query GetVendor($vendorID: ID!) {
  getVendor(vendorID: $vendorID) {
    vendorID
    vendorType
    vendorName
    vendorSubheading
    vendorDescription
    vendorValueProp
    vendorImgPath
    vendorMainImg
    vendorVideoLink
    vendorPhone
    vendorEmail
    vendorWebsiteURL
    bookables {
      items {
        bookableID
        bookableName
        bookableSubheading
        bookableDescription
        bookableIsPortable
        bookableLocation
        bookableImgPath
        bookableMainImg
        bookableVideoLink
        bookablePrice
        bookablePriceType
        bookableDuration
        bookableMinOccupancy
        bookableMaxOccupancy
        createdAt
        updatedAt
        vendorBookablesVendorID
        owner
        __typename
      }
      nextToken
      __typename
    }
    vendorLocation
    vendorExperiences
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetVendorQueryVariables, APITypes.GetVendorQuery>;
export const listVendors = /* GraphQL */ `query ListVendors(
  $vendorID: ID
  $filter: ModelVendorFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listVendors(
    vendorID: $vendorID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      vendorID
      vendorType
      vendorName
      vendorSubheading
      vendorDescription
      vendorValueProp
      vendorImgPath
      vendorMainImg
      vendorVideoLink
      vendorPhone
      vendorEmail
      vendorWebsiteURL
      bookables {
        nextToken
        __typename
      }
      vendorLocation
      vendorExperiences
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVendorsQueryVariables,
  APITypes.ListVendorsQuery
>;
export const vendorByOwner = /* GraphQL */ `query VendorByOwner(
  $owner: String!
  $sortDirection: ModelSortDirection
  $filter: ModelVendorFilterInput
  $limit: Int
  $nextToken: String
) {
  vendorByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      vendorID
      vendorType
      vendorName
      vendorSubheading
      vendorDescription
      vendorValueProp
      vendorImgPath
      vendorMainImg
      vendorVideoLink
      vendorPhone
      vendorEmail
      vendorWebsiteURL
      bookables {
        nextToken
        __typename
      }
      vendorLocation
      vendorExperiences
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VendorByOwnerQueryVariables,
  APITypes.VendorByOwnerQuery
>;
export const getBookable = /* GraphQL */ `query GetBookable($bookableID: ID!) {
  getBookable(bookableID: $bookableID) {
    bookableID
    parentVendor {
      vendorID
      vendorType
      vendorName
      vendorSubheading
      vendorDescription
      vendorValueProp
      vendorImgPath
      vendorMainImg
      vendorVideoLink
      vendorPhone
      vendorEmail
      vendorWebsiteURL
      bookables {
        nextToken
        __typename
      }
      vendorLocation
      vendorExperiences
      owner
      createdAt
      updatedAt
      __typename
    }
    bookableName
    bookableSubheading
    bookableDescription
    bookableIsPortable
    bookableLocation
    bookableImgPath
    bookableMainImg
    bookableVideoLink
    bookablePrice
    bookablePriceType
    bookableDuration
    bookableMinOccupancy
    bookableMaxOccupancy
    categories {
      items {
        id
        subCategoryId
        bookableBookableID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    vendorBookablesVendorID
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookableQueryVariables,
  APITypes.GetBookableQuery
>;
export const listBookables = /* GraphQL */ `query ListBookables(
  $bookableID: ID
  $filter: ModelBookableFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBookables(
    bookableID: $bookableID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      bookableID
      parentVendor {
        vendorID
        vendorType
        vendorName
        vendorSubheading
        vendorDescription
        vendorValueProp
        vendorImgPath
        vendorMainImg
        vendorVideoLink
        vendorPhone
        vendorEmail
        vendorWebsiteURL
        vendorLocation
        vendorExperiences
        owner
        createdAt
        updatedAt
        __typename
      }
      bookableName
      bookableSubheading
      bookableDescription
      bookableIsPortable
      bookableLocation
      bookableImgPath
      bookableMainImg
      bookableVideoLink
      bookablePrice
      bookablePriceType
      bookableDuration
      bookableMinOccupancy
      bookableMaxOccupancy
      categories {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vendorBookablesVendorID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookablesQueryVariables,
  APITypes.ListBookablesQuery
>;
export const getBookingRequest = /* GraphQL */ `query GetBookingRequest($id: ID!) {
  getBookingRequest(id: $id) {
    id
    customerAndVendorIDs
    bookingBookableID
    bookingVendorID
    bookingRequestorUserID
    bookingRequestorName
    bookingRequestorEmail
    bookingNumAttendees
    bookingType
    bookingStartDateTime
    bookingDuration
    bookingDurationUnit
    bookingStatus
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookingRequestQueryVariables,
  APITypes.GetBookingRequestQuery
>;
export const listBookingRequests = /* GraphQL */ `query ListBookingRequests(
  $id: ID
  $filter: ModelBookingRequestFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBookingRequests(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      customerAndVendorIDs
      bookingBookableID
      bookingVendorID
      bookingRequestorUserID
      bookingRequestorName
      bookingRequestorEmail
      bookingNumAttendees
      bookingType
      bookingStartDateTime
      bookingDuration
      bookingDurationUnit
      bookingStatus
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookingRequestsQueryVariables,
  APITypes.ListBookingRequestsQuery
>;
export const bookingRequestByVendor = /* GraphQL */ `query BookingRequestByVendor(
  $bookingVendorID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelbookingRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingRequestByVendor(
    bookingVendorID: $bookingVendorID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      customerAndVendorIDs
      bookingBookableID
      bookingVendorID
      bookingRequestorUserID
      bookingRequestorName
      bookingRequestorEmail
      bookingNumAttendees
      bookingType
      bookingStartDateTime
      bookingDuration
      bookingDurationUnit
      bookingStatus
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingRequestByVendorQueryVariables,
  APITypes.BookingRequestByVendorQuery
>;
export const bookingRequestByRequestingUser = /* GraphQL */ `query BookingRequestByRequestingUser(
  $bookingRequestorUserID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelbookingRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingRequestByRequestingUser(
    bookingRequestorUserID: $bookingRequestorUserID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      customerAndVendorIDs
      bookingBookableID
      bookingVendorID
      bookingRequestorUserID
      bookingRequestorName
      bookingRequestorEmail
      bookingNumAttendees
      bookingType
      bookingStartDateTime
      bookingDuration
      bookingDurationUnit
      bookingStatus
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingRequestByRequestingUserQueryVariables,
  APITypes.BookingRequestByRequestingUserQuery
>;
export const getMessage = /* GraphQL */ `query GetMessage($id: ID!) {
  getMessage(id: $id) {
    id
    senderAndRecipientCognitoIDs
    senderCognitoID
    senderName
    senderPhone
    senderEmail
    recipientInternalID
    recipientType
    messageSubject
    messageBody
    messageOriginalMessageID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMessageQueryVariables,
  APITypes.GetMessageQuery
>;
export const listMessages = /* GraphQL */ `query ListMessages(
  $id: ID
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMessages(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      senderAndRecipientCognitoIDs
      senderCognitoID
      senderName
      senderPhone
      senderEmail
      recipientInternalID
      recipientType
      messageSubject
      messageBody
      messageOriginalMessageID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessagesQueryVariables,
  APITypes.ListMessagesQuery
>;
export const messagesFromUser = /* GraphQL */ `query MessagesFromUser(
  $senderCognitoID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  messagesFromUser(
    senderCognitoID: $senderCognitoID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      senderAndRecipientCognitoIDs
      senderCognitoID
      senderName
      senderPhone
      senderEmail
      recipientInternalID
      recipientType
      messageSubject
      messageBody
      messageOriginalMessageID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MessagesFromUserQueryVariables,
  APITypes.MessagesFromUserQuery
>;
export const messagesToUser = /* GraphQL */ `query MessagesToUser(
  $recipientInternalID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  messagesToUser(
    recipientInternalID: $recipientInternalID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      senderAndRecipientCognitoIDs
      senderCognitoID
      senderName
      senderPhone
      senderEmail
      recipientInternalID
      recipientType
      messageSubject
      messageBody
      messageOriginalMessageID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MessagesToUserQueryVariables,
  APITypes.MessagesToUserQuery
>;
export const messagesInThread = /* GraphQL */ `query MessagesInThread(
  $messageOriginalMessageID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  messagesInThread(
    messageOriginalMessageID: $messageOriginalMessageID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      senderAndRecipientCognitoIDs
      senderCognitoID
      senderName
      senderPhone
      senderEmail
      recipientInternalID
      recipientType
      messageSubject
      messageBody
      messageOriginalMessageID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MessagesInThreadQueryVariables,
  APITypes.MessagesInThreadQuery
>;
export const getBookablesCategories = /* GraphQL */ `query GetBookablesCategories($id: ID!) {
  getBookablesCategories(id: $id) {
    id
    subCategoryId
    bookableBookableID
    subCategory {
      id
      parentCategoryID
      subCategoryName
      subCategoryImg
      showAsVendors
      bookables {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    bookable {
      bookableID
      parentVendor {
        vendorID
        vendorType
        vendorName
        vendorSubheading
        vendorDescription
        vendorValueProp
        vendorImgPath
        vendorMainImg
        vendorVideoLink
        vendorPhone
        vendorEmail
        vendorWebsiteURL
        vendorLocation
        vendorExperiences
        owner
        createdAt
        updatedAt
        __typename
      }
      bookableName
      bookableSubheading
      bookableDescription
      bookableIsPortable
      bookableLocation
      bookableImgPath
      bookableMainImg
      bookableVideoLink
      bookablePrice
      bookablePriceType
      bookableDuration
      bookableMinOccupancy
      bookableMaxOccupancy
      categories {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vendorBookablesVendorID
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookablesCategoriesQueryVariables,
  APITypes.GetBookablesCategoriesQuery
>;
export const listBookablesCategories = /* GraphQL */ `query ListBookablesCategories(
  $filter: ModelBookablesCategoriesFilterInput
  $limit: Int
  $nextToken: String
) {
  listBookablesCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subCategoryId
      bookableBookableID
      subCategory {
        id
        parentCategoryID
        subCategoryName
        subCategoryImg
        showAsVendors
        createdAt
        updatedAt
        owner
        __typename
      }
      bookable {
        bookableID
        bookableName
        bookableSubheading
        bookableDescription
        bookableIsPortable
        bookableLocation
        bookableImgPath
        bookableMainImg
        bookableVideoLink
        bookablePrice
        bookablePriceType
        bookableDuration
        bookableMinOccupancy
        bookableMaxOccupancy
        createdAt
        updatedAt
        vendorBookablesVendorID
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookablesCategoriesQueryVariables,
  APITypes.ListBookablesCategoriesQuery
>;
export const bookablesCategoriesBySubCategoryId = /* GraphQL */ `query BookablesCategoriesBySubCategoryId(
  $subCategoryId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBookablesCategoriesFilterInput
  $limit: Int
  $nextToken: String
) {
  bookablesCategoriesBySubCategoryId(
    subCategoryId: $subCategoryId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subCategoryId
      bookableBookableID
      subCategory {
        id
        parentCategoryID
        subCategoryName
        subCategoryImg
        showAsVendors
        createdAt
        updatedAt
        owner
        __typename
      }
      bookable {
        bookableID
        bookableName
        bookableSubheading
        bookableDescription
        bookableIsPortable
        bookableLocation
        bookableImgPath
        bookableMainImg
        bookableVideoLink
        bookablePrice
        bookablePriceType
        bookableDuration
        bookableMinOccupancy
        bookableMaxOccupancy
        createdAt
        updatedAt
        vendorBookablesVendorID
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookablesCategoriesBySubCategoryIdQueryVariables,
  APITypes.BookablesCategoriesBySubCategoryIdQuery
>;
export const bookablesCategoriesByBookableBookableID = /* GraphQL */ `query BookablesCategoriesByBookableBookableID(
  $bookableBookableID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBookablesCategoriesFilterInput
  $limit: Int
  $nextToken: String
) {
  bookablesCategoriesByBookableBookableID(
    bookableBookableID: $bookableBookableID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subCategoryId
      bookableBookableID
      subCategory {
        id
        parentCategoryID
        subCategoryName
        subCategoryImg
        showAsVendors
        createdAt
        updatedAt
        owner
        __typename
      }
      bookable {
        bookableID
        bookableName
        bookableSubheading
        bookableDescription
        bookableIsPortable
        bookableLocation
        bookableImgPath
        bookableMainImg
        bookableVideoLink
        bookablePrice
        bookablePriceType
        bookableDuration
        bookableMinOccupancy
        bookableMaxOccupancy
        createdAt
        updatedAt
        vendorBookablesVendorID
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookablesCategoriesByBookableBookableIDQueryVariables,
  APITypes.BookablesCategoriesByBookableBookableIDQuery
>;
