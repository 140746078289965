import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { CreateBookableInput, CreateBookablesCategoriesInput } from "../../API";
import { useGlobalContext } from "../../App";
import { fetchBookableByID, fetchBookableCategoriesByBookableID, parseImgPath, s3ImgParams } from "../../Utils";
import { VendorPriceBreakdown } from "../../components/SteppedForm";
import { Nav } from "../../components/nav";

export function ViewBookable() {
  let { bookableID } = useParams();
  let vendor = useGlobalContext().vendor;
  const [bkbl, setBkbl] = useState<CreateBookableInput | undefined>();
  const [categories, setCategories] = useState<CreateBookablesCategoriesInput[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (bookableID) {
        const newBkbl = await fetchBookableByID(bookableID);
        setBkbl(newBkbl);

        const newCats = await fetchBookableCategoriesByBookableID(bookableID);
        setCategories(newCats);

        setLoading(false);
      }
    })();
  }, [bookableID]);

  return (
    <>
      <section className="landing-section">
        <div className="landing-div">
          <div>
            <Nav title={bkbl?.bookableName ? bkbl?.bookableName : ""} />
          </div>
        </div>
      </section>
      <div style={{ padding: "2rem 1.5rem", width: "80%", display: "flex", flexDirection: "column", margin: "0 auto 0 auto" }}>
        {loading && (
          <div className="landing-div">
            <h5 style={{ margin: 0 }}>Retrieving details...</h5>
          </div>
        )}
        {!loading && bkbl && (
          <div className="landing-div">
            <Grid container direction="row">
              <Grid container item xs={5.5} direction="column" mr="2rem">
                <Grid item padding={"1rem"}>
                  <img
                    src={s3ImgParams.s3ImgPath + parseImgPath(bkbl.bookableImgPath) + "/" + encodeURIComponent(bkbl.bookableMainImg)}
                    alt="service main"
                    style={{
                      borderRadius: "20px",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
                <Grid item padding={"1rem"}>
                  {VendorPriceBreakdown(bkbl.bookablePrice)}
                </Grid>
              </Grid>
              <Grid container item xs={6} direction="column" padding={"1rem"}>
                <Grid container item direction="row">
                  <Grid item xs={3}>
                    <span className="desktop-body" style={{ fontWeight: "bold" }}>
                      Name:
                    </span>
                  </Grid>
                  <Grid item xs={9}>
                    <span className="desktop-body">{bkbl.bookableName}</span>
                  </Grid>
                </Grid>

                <Grid container item direction="row">
                  <Grid item xs={3}>
                    <span className="desktop-body" style={{ fontWeight: "bold" }}>
                      Subheading:
                    </span>
                  </Grid>
                  <Grid item xs={9}>
                    <span className="desktop-body">{bkbl.bookableSubheading}</span>
                  </Grid>
                </Grid>

                <Grid container item direction="row">
                  <Grid item xs={3}>
                    <span className="desktop-body" style={{ fontWeight: "bold" }}>
                      Categories:
                    </span>
                  </Grid>
                  <Grid item xs={9}>
                    <span className="desktop-body">
                      {vendor && vendor.vendorType + " > "}
                      {categories && categories.length === 0 && "No subcategories for this booking option"}

                      {categories &&
                        categories.length > 0 &&
                        categories.map((cat, i) => {
                          return i > 0 ? ", " + cat.subCategoryId : cat.subCategoryId;
                        })}
                    </span>
                  </Grid>

                  <Grid container item direction="row">
                    <Grid item xs={3}>
                      <span className="desktop-body" style={{ fontWeight: "bold" }}>
                        Duration
                      </span>
                    </Grid>
                    <Grid item xs={9}>
                      <span className="desktop-body">{bkbl.bookableDuration} hrs</span>
                    </Grid>
                  </Grid>
                  <Grid container item direction="row">
                    <Grid item xs={3}>
                      <span className="desktop-body" style={{ fontWeight: "bold" }}>
                        Attendees
                      </span>
                    </Grid>
                    <Grid item xs={9}>
                      <span className="desktop-body">{bkbl.bookableMinOccupancy + " - " + bkbl.bookableMaxOccupancy}</span>
                    </Grid>
                  </Grid>

                  <Grid container item direction="row" mt="1rem">
                    <Grid item xs={3}>
                      <Link to={"/pages/editBookable/" + bkbl.bookableID}>
                        <button className="button-transparent">
                          <span className="button-text button-text-cream">Edit booking option</span>
                        </button>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </>
  );
}
