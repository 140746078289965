import { Button, Grid, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Bookable, CreateBookableInput } from "../../../API";
import { locationType } from "../../../components/Map/Map";
import { getS3ImagesInFolder, s3ImgParams } from "../../../Utils";
import Map from "../../../components/Map/Map";
import ImageGallery from "../../../components/ImageGallery";

import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

const placeholderImage = require("../../../img/svc_placeholder_1.png");

export default function BookableDetailElement(props: {
  bookable: CreateBookableInput;
  vendorID?: string;
  vendorName?: string;
  showEditBtn?: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [imgs, setImgs] = useState<string[]>([]);
  const [imgsLoading, setImgsLoading] = useState(true);
  const [galleryModalOpen, setGalleryModalOpen] = useState(false);
  const bookableLocation: locationType = JSON.parse(props.bookable.bookableLocation);

  useEffect(() => {
    setImgsLoading(true);

    (async () => {
      try {
        const fetchedImages = await getS3ImagesInFolder(props.bookable.bookableImgPath);

        const mainImgIndex = fetchedImages.indexOf(props.bookable.bookableImgPath + "/" + props.bookable.bookableMainImg);
        if (mainImgIndex > -1) {
          // only splice array when item is found
          fetchedImages.splice(mainImgIndex, 1); // 2nd parameter means remove one item only
        }

        setImgs(fetchedImages);
        setImgsLoading(false);
      } catch (error) {
        console.error("Error finding images:", error);
      }
    })();
  }, [props.bookable]);

  return (
    <>
      <Modal
        open={galleryModalOpen}
        onClose={(e) => {
          setGalleryModalOpen(false);
        }}
        onClick={(e) => {
          setGalleryModalOpen(false);
        }}
        sx={{
          background: "rgba(0, 0, 0, 0.5)",
          overflow: "scroll",
        }}
      >
        <div
          className="landing-div"
          style={{
            width: "90%",
            height: "auto",
            margin: "4rem auto",
            borderRadius: "15px",
            padding: "2rem",
          }}
        >
          <ImageGallery imgs={imgs} mainImg={props.bookable.bookableMainImg} />
        </div>
      </Modal>
      <div style={{ margin: "auto auto 1rem .5rem" }}>
        <button className="button-transparent button-small" onClick={() => props.setModalOpen(false)} style={{ marginRight: "1rem" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CloseIcon />
            <span className="button-text small-button-text">Close preview</span>
          </div>
        </button>
        {props.showEditBtn && (
          <Link to={"/pages/editBookable/" + props.bookable.bookableID}>
            <button className="button-lime button-small">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <EditIcon />
                <span className="button-text-lime small-button-text">Edit</span>
              </div>
            </button>
          </Link>
        )}
      </div>
      <Grid container direction="column">
        <Grid
          container
          item
          direction="row"
          style={{
            margin: "0 auto 0 auto",
            height: props.bookable.bookableMainImg ? "50vh" : "25vh",
            minHeight: props.bookable.bookableMainImg ? "500px" : "240px",
          }}
        >
          <Grid item xs={imgs.length === 1 ? 12 : imgs.length === 2 ? 6 : 8} padding="1rem .5rem" height="100%">
            <div
              style={{
                display: "flex",
                backgroundColor: props.bookable.bookableMainImg ? "var(--smoke-grey)" : "white",
                borderRadius: "20px",
                width: "100%",
                height: "100%",
              }}
            >
              <img
                src={props.bookable.bookableMainImg ? s3ImgParams.s3ImgPath + props.bookable.bookableImgPath + "/" + encodeURIComponent(props.bookable.bookableMainImg) : placeholderImage}
                alt="service main"
                style={{
                  borderRadius: "20px",
                  width: "100%",
                  height: "100%",
                  objectFit: props.bookable.bookableMainImg ? "cover" : "contain",
                }}
                onClick={() => setGalleryModalOpen(true)}
              />
            </div>
          </Grid>
          {imgs.length > 1 && (
            <Grid container item xs={imgs.length === 2 ? 6 : 4} direction="column" height="100%" padding="1rem .5rem">
              {imgs.map((img, i) => {
                if (img !== props.bookable.bookableImgPath + "/" + props.bookable.bookableMainImg && i <= 2 && i <= imgs.length - 1) {
                  return (
                    <Grid item xs={imgs.length === 2 ? 12 : 6} height={imgs.length === 2 ? "100%" : "50%"} paddingBottom={imgs.length === 2 ? 0 : i === 2 ? 0 : ".5rem"} key={"imgGrid" + i}>
                      <div
                        style={{
                          backgroundColor: "var(--smoke-grey)",
                          borderRadius: "20px",
                          width: "100%",
                          height: "100%",
                        }}
                        key={"imgDiv" + i}
                      >
                        <img
                          src={s3ImgParams.s3ImgPath + encodeURIComponent(imgs[i])}
                          alt="service image"
                          style={{
                            borderRadius: "20px",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          key={"img" + i}
                          onClick={() => setGalleryModalOpen(true)}
                        />
                      </div>
                    </Grid>
                  );
                }
              })}
            </Grid>
          )}
        </Grid>

        <Grid container item direction="row" style={{ margin: "1rem auto 0 auto" }}>
          <Grid container item xs={8} direction="column" paddingRight="1.5rem">
            <div style={{ textAlign: "left" }}>
              <h5 style={{ margin: "1rem 0" }}>{props.bookable.bookableName}</h5>
            </div>
            {props.bookable.bookableSubheading && (
              <div style={{ textAlign: "left" }}>
                <h5
                  style={{
                    color: "var(--forest-green)",
                    marginTop: 0,
                    fontSize: "22px",
                  }}
                >
                  {props.bookable.bookableSubheading}
                </h5>
              </div>
            )}
            <div style={{ textAlign: "left" }}>
              <span className="desktop-body">{props.bookable.bookableDescription}</span>
            </div>

            {/* props.vendorID && props.vendorName && (
              <div style={{ textAlign: "left", margin: "auto auto 2rem auto" }}>
                <Link to={"/pages/vendorDetail/" + props.vendorID}>
                  <button className="button-transparent">
                    <span className="button-text button-text-cream">
                      More from {props.vendorName}
                    </span>
                  </button>
                </Link>
              </div>
            ) */}
          </Grid>
          <Grid container item xs={4} direction="column" padding=".5rem">
            <div
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "1rem",
                border: "2px solid var(--smoke-grey)",
                borderRadius: "15px",
                padding: "1rem",
                textAlign: "left",
              }}
            >
              <div>
                <span className="desktop-body">from </span>
                <span className="desktop-body" style={{ fontWeight: "bold" }}>
                  ${props.bookable.bookablePrice}
                </span>
                {props.bookable.bookablePriceType === "perPerson" && <span className="desktop-body"> per person</span>}
              </div>
              <div>
                <span className="desktop-body">{props.bookable.bookableMinOccupancy + "-" + props.bookable.bookableMaxOccupancy + " people"}</span>
              </div>
              <div>
                <span className="desktop-body">{props.bookable.bookableDuration + " hours"}</span>
              </div>

              <div style={{ marginTop: "1rem", textAlign: "center" }}>
                <Button variant="contained" color={"info"} sx={{ minWidth: "100%" }}>
                  Book {props.bookable.bookableName}
                </Button>
                <span className="desktop-body" style={{ fontWeight: "light", fontSize: "14px" }}>
                  We'll confirm availability for your desired booking date.
                </span>
              </div>
            </div>
            <div style={{ padding: ".5rem", marginRight: "1rem" }}>
              <Map
                vendorLocations
                mapOrigin={bookableLocation}
                markerLocations={props.bookable.bookableIsPortable ? [] : [bookableLocation]}
                distanceSliderValue={4}
                zoomLevelOverride={props.bookable.bookableIsPortable ? 5 : 9}
                height={"300px"}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
