import { LandingPage } from "./pages/landingPage";
import { CreateBookable } from "./components/CreateBookable";
import { EditBookable } from "./pages/editBookable";
import { ViewBookable } from "./pages/viewBookable";
import { EditVendor } from "./pages/editVendor";

const routes = [
  {
    name: "landingPage",
    route: "/",
    component: <LandingPage />,
    authenticated: true,
  },
  {
    name: "createBookable",
    route: "/pages/createBookable",
    component: <CreateBookable />,
    authenticated: true,
  },
  {
    name: "editBookable",
    route: "/pages/editBookable/:bookableID",
    component: <EditBookable />,
    authenticated: true,
  },
  {
    name: "viewBookable",
    route: "/pages/viewBookable/:bookableID",
    component: <ViewBookable />,
    authenticated: true,
  },
  {
    name: "editVendor",
    route: "/pages/editVendor",
    component: <EditVendor />,
    authenticated: true,
  },
];

export default routes;
