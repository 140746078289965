import React, { createContext, useContext, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "./routes";

import { Authenticator, Button, Heading, Image, Text, View, useAuthenticator, useTheme } from "@aws-amplify/ui-react";
import { LandingPage } from "./pages/landingPage";

import "./theme/landingpage.css";
import { CreateVendorInput } from "./API";

export interface globalContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  vendor: CreateVendorInput | undefined;
  setVendor: React.Dispatch<React.SetStateAction<CreateVendorInput | undefined>>;
}

export const GlobalContext = createContext<globalContextType>({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  vendor: undefined,
  setVendor: () => {},
});

export const useGlobalContext = () => useContext(GlobalContext);

const authenticatorComponents = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <h5 style={{ margin: "0 auto 2rem auto" }}>
          <p style={{ marginBottom: "1rem" }}>
            Listing with Townish helps all kinds of companies to find <b>you</b> for their next event.
          </p>
          <Image alt="Townish logo" src={require("./img/Townish_logo_inverted_circle.png")} />
          <p style={{ marginBottom: 0 }}>Create an account or sign in to get started</p>
        </h5>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>&copy; Townish - All Rights Reserved</Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button fontWeight="normal" onClick={toForgotPassword} size="small" variation="link">
            Reset Password
          </Button>
        </View>
      );
    },
  },
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [vendor, setVendor] = useState<CreateVendorInput | undefined>();

  const getRoutes = (allRoutes: any) =>
    allRoutes.map((route: any) => {
      if (!vendor && route.authenticated) {
        return <Route path={"/"} key={route.route} element={<LandingPage />} />;
      }
      if (route.route) {
        return <Route path={route.route} element={route.component} key={route.route} />;
      }

      return null;
    });

  return (
    <GlobalContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        setIsAuthenticated: setIsAuthenticated,
        vendor: vendor,
        setVendor: setVendor,
      }}
    >
      <Authenticator components={authenticatorComponents}>
        <div className="App">
          <link href="https://fonts.googleapis.com/css?family=Cabin" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet"></link>
          <Routes>
            {getRoutes(routes)}
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Authenticator>
    </GlobalContext.Provider>
  );
}

export default App;
