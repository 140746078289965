export function VendorTCs() {
  return (
    <>
      <p
        className="s1"
        style={{
          paddingTop: "3pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        TOWNISH VENDOR TERMS OF SERVICE
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p className="s2" style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
        Last Updated: November 9,2022
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        THESE TERMS AND CONDITIONS (THIS "<b>Agreement</b>") CONSTITUTE A BINDING CONTRACT BETWEEN THE ENTITY IDENTIFIED IN THE REGISTRATION PAGE ("
        <b>Vendor</b>"
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
        or "<b>you</b>") AND TOWNISH, INC., a company to be incorporated under the laws of the Provine of Ontario <b>("Townish", "we", "us" </b>and "<b>our</b>
        "). THIS AGREEMENT SHALL GOVERN VENDOR'S USE OF THE "PLATFORM" (AS DEFINED BELOW) THROUGH WHICH PARTICIPATING VENDOR(S) CAN MAKE THEIR EXPERIENCE (AS DEFINED BELOW) AVAILABLE FOR RESERVATION
        BY BUYER(S) (AS DEFINED BELOW) INTERESTED IN OFF-SITE WORK-RELATED EXPERIENCES, AND THROUGH WHICH BUYER(S) CAN MAKE RESERVATIONS FOR SUCH EXPERIENCES. IF YOU ARE ENTERING INTO THIS AGREEMENT
        ON BEHALF OF AN ENTITY, YOU REPRESENT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO BIND SUCH ENTITY TO THIS AGREEMENT. BY CLICKING "I AGREE" BELOW, OR BY OTHERWISE ACCESSING OR USING
        THE PLATFORM (AS DEFINED BELOW), YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE FOLLOWING (THE DATE OF SUCH
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
        OCCURRENCE BEING THE "<b>Effective Date</b>"):
      </p>
      <ol id="l1">
        <li data-list-text="(i)">
          <p
            style={{
              paddingTop: "12pt",
              paddingLeft: "59pt",
              textIndent: "-35pt",
              textAlign: "justify",
            }}
          >
            THIS AGREEMENT, AND
          </p>
        </li>
        <li data-list-text="(ii)">
          <p
            style={{
              paddingLeft: "59pt",
              textIndent: "-36pt",
              textAlign: "justify",
            }}
          >
            OTHER SUPPLEMENTAL TERMS AND POLICIES REFERENCED HEREIN, WHICH ARE HEREBY INCORPORATED INTO, AND MADE A PART OF, THIS AGREEMENT BY REFERENCE.
          </p>
        </li>
      </ol>
      <p
        style={{
          paddingTop: "12pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        IF YOU DO NOT AGREE WITH ANY OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY NOT ACCESS OR USE THE PLATFORM (defined below).
      </p>
      <p
        style={{
          paddingTop: "12pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        This Agreement (including its Schedules) represents the entire agreement between Townish and Vendor with respect to the subject matter hereof, and supersedes and replaces any and all prior and
        contemporaneous oral and/or written agreements, understandings and statements between Vendor and Townish with respect to such subject matter. Vendor acknowledges and agrees that in entering
        into this Agreement it has not relied on any statement or representation (whether negligently or innocently made) not expressly set out in this Agreement; for example, statements and
        explanations in any FAQs or other marketing material on the Platform are for convenience only, and are not binding or a part of this Agreement. The language of this Agreement is expressly
        agreed to be the English language. By entering into the Agreement Vendor hereby irrevocably waives, to the maximum extent legally permitted, any Law (defined below) applicable to Vendor
        requiring that the Agreement be localized to meet Vendor's language, as well as any other localization requirements.
      </p>
      <p
        style={{
          paddingTop: "12pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        Townish reserves the right to modify this Agreement at any time by posting the modified Agreement at{" "}
        <span
          style={{
            color: "#00F",
            fontFamily: '"Times New Roman", serif',
            fontStyle: "normal",
            fontWeight: "normal",
            textDecoration: "underline",
            fontSize: "11pt",
          }}
        >
          <a href="http://www.vendors.townish.ca/" className="s7" target="_blank" rel="noreferrer">
            vendors.townish.ca
          </a>
          .
        </span>{" "}
        Such modifications will be effective ten (10) days after such posting, and Vendor's continued use of the Platform shall constitute Vendor's agreement to such modifications. In such cases, we
        will also update the "<i>Last Updated</i>" date set forth above. Please check the above webpage regularly for any modifications.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <ol id="l2">
        <li data-list-text={1}>
          <p className="s1" style={{ paddingLeft: "14pt", textIndent: "-8pt", textAlign: "left" }}>
            {" "}
            &nbsp;&nbsp; ​ DEFINITIONS AND INTERPRETATION
          </p>
          <p
            style={{
              paddingTop: "12pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            This Agreement contains a range of capitalized terms, some of which are defined in this Section, and some of which are defined elsewhere. The Section and sub-Section headings in this
            Agreement are for convenience of reading only, and may not to be used or relied upon for interpretive purposes.
          </p>
          <p
            style={{
              paddingTop: "12pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            "<b>Buyer</b>" means a person who purchases one or more Experiences through the Platform.
          </p>
          <p style={{ paddingTop: "4pt", textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            "<b>Content</b>" means any text, data, information, images, graphics, sounds, videos, audio clips, links, and/or other similar materials.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            "<b>Documentation</b>" means any electronic manuals, specifications, and similar documentation made available by Townish for use of the Platform (such as data format and data feed
            specifications).
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            "<b>Experience</b>" means a travel experience, hotel, or any other accommodation, entertainment or any other experience listed by Vendor for sale on the Platform.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
            "<b>Feature</b>" means any module, tool, and/or feature of the Platform (such as those providing for language translations, currency conversions, tax calculations).
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>.</p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            "<b>Intellectual Property</b>" means any and all inventions, discoveries, improvements, works of authorship, technical information, data, technology, know-how, show-how, designs, drawings,
            utility models, topography and semiconductor mask works, specifications, formulas, methods, techniques, processes, databases, computer software and programs (including object code, source
            code, APIs, and non-literal aspects), algorithms, architecture, records, documentation, and other similar intellectual property, in any form and embodied in any media.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            "<b>Intellectual Property Rights</b>" means any and all rights, titles, and interests in and to Intellectual Property (under any jurisdiction or treaty, whether protectable or not, and
            whether registered or unregistered), and includes without limitation patents, copyright and similar authorship rights, personal rights (such as Moral Rights, rights of privacy, and
            publicity rights), architectural, building and location (and similar geography-based) rights, mask work rights, trade secret and similar confidentiality rights, design rights, industrial
            property rights, trademark, trade name, trade dress and similar branding rights, as well as: (a) all applications, registrations, renewals, extensions, continuations,
            continuations-in-part, divisions or reissues of the foregoing rights; and (b) all goodwill associated with the foregoing rights.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            "<b>Law</b>" means any federal, provincial, state, foreign, regional or local statute, regulation, ordinance, or rule of any jurisdiction.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            "<b>Moral Rights</b>" means any rights of paternity or integrity, or any right to claim authorship of a work, to object to any distortion, mutilation or other modification of, or other
            derogatory action in relation to, any work, whether or not such would be prejudicial to the honor or reputation of the creator of the work, and any similar right, existing under judicial
            or statutory Law of any jurisdiction, or under any treaty.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            "<b>Order</b>" means an order for the purchase of one or more Experience(s), which is issued by Buyer to Vendor via the Platform.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            "<b>Platform</b>" means Townish's proprietary online marketplace, called <i>Townish</i>, which is made available via the Site. For the purposes of this Agreement, references herein to
            "Platform" shall also be deemed to include the Vendor Centre and all related APIs and other technology made available by Townish to enable Vendor to sell the Experiences on the Platform.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            "<b>Site</b>" means{" "}
            <span
              style={{
                color: "#00F",
                fontFamily: '"Times New Roman", serif',
                fontStyle: "normal",
                fontWeight: "normal",
                textDecoration: "underline",
                fontSize: "11pt",
              }}
            >
              <a href="http://www.townish.ca/" className="s7" target="_blank" rel="noreferrer">
                www.townish.ca
              </a>
            </span>{" "}
            (together with any of its subdomains).
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            "<b>Update</b>" means an upgrade, update (such as a fix or patch), or other modification, improvement, enhancement or customization to or of the Platform.
          </p>
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            "<b>Vendor Centre</b>" means Townish's proprietary Vendor management portal as may be available at{" "}
            <span
              style={{
                color: "#00F",
                fontFamily: '"Times New Roman", serif',
                fontStyle: "normal",
                fontWeight: "normal",
                textDecoration: "underline",
                fontSize: "11pt",
              }}
            >
              <a href="http://vendors.townish.ca/" className="s7" target="_blank" rel="noreferrer">
                vendors.townish.ca
              </a>
            </span>
            .
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            "<b>Vendor Content</b>" means Content (whether or not proprietary to Vendor) inputted by Vendor to, or otherwise generated by, its Vendor Account, and/or that is otherwise provided or made
            available by Vendor (or by Townish on Vendor's behalf) to Buyers on the Platform (such as Experience descriptions and images, Experience pricing and shipping information, Order statuses,
            Vendor's trademarks, service marks, brand names, and logos <i>etc.</i>).
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text={2}>
          <p className="s1" style={{ paddingLeft: "14pt", textIndent: "-8pt", textAlign: "left" }}>
            {" "}
            &nbsp;&nbsp; ​ VENDOR ACCOUNT
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            In order to sell Experiences on the Platform, Vendor may be required to register for an account by submitting the information requested in the applicable web form (the "
            <b>Vendor Account</b>"). Vendor represents and warrants that all information submitted during the registration process is, and will thereafter remain, complete and accurate. As between
            Vendor and Townish, Vendor alone shall be responsible and liable for maintaining the confidentiality and security of its Vendor Account credentials, as well as for all activities that
            occur under or in the Vendor Account. Vendor shall immediately notify Townish in writing of any unauthorized access to, or use of, the Vendor Account, or any other suspected or actual
            breach of security or malicious use; but Vendor also acknowledges that Townish may be unable to remedy the damage or loss (or otherwise assist) in such scenarios. Personally identifiable
            information received during the Vendor Account registration process will be stored and used by Townish in accordance with the Privacy Policy.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            As an alternative to registering for a Vendor Account as described above, Townish may, in its sole discretion and on a temporary basis, permit Vendor to create and manage a Vendor Account
            on the Platform (and to access and use the Vendor Centre) via a third party integration partner that provides connectivity to the Vendor Centre (a "<b>Third Party Integrator</b>"). In such
            cases, Vendor shall be responsible for reviewing and agreeing to whatever other terms and conditions required by such Third Party Integrator for access to its platform (in addition to
            entering into this Agreement). Townish shall have no responsibility in relation to Vendor’s interaction or contract with any such Third Party Integrator.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text={3}>
          <p className="s1" style={{ paddingLeft: "14pt", textIndent: "-8pt", textAlign: "left" }}>
            {" "}
            &nbsp;&nbsp; ​ ACCESS
          </p>
          <ol id="l3">
            <li data-list-text="(a)">
              <p
                style={{
                  paddingLeft: "46pt",
                  textIndent: "-12pt",
                  textAlign: "left",
                }}
              >
                Subject to the terms and conditions of this Agreement, Townish grants Vendor a limited, non-exclusive, non-assignable, non-sublicensable, revocable right, during the Term (defined
                below), to access and use the Platform solely for Vendor's own internal business purposes of selling its Experiences (collectively, the "<b>License</b>").
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text={4}>
          <p className="s1" style={{ paddingLeft: "14pt", textIndent: "-8pt", textAlign: "left" }}>
            {" "}
            &nbsp;&nbsp; ​ USAGE RESTRICTIONS
          </p>
          <ol id="l4">
            <li data-list-text="(d)">
              <p
                style={{
                  paddingLeft: "18pt",
                  textIndent: "-12pt",
                  textAlign: "left",
                }}
              >
                ​
              </p>
            </li>
            <li data-list-text="(e)">
              <p
                style={{
                  paddingLeft: "52pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Vendor shall not (and shall not permit or encourage any third party to) do any of the following: (a) reproduce the Platform; (b) sell, assign, lease, lend, rent, distribute, or make
                available the Platform to any third party, or otherwise offer or use the Platform in a time-sharing, outsourcing, or service bureau environment, nor will you allow any third party to
                use the Platform through your Vendor Account; (c) modify, alter, adapt, arrange, translate, decompile, disassemble, reverse engineer, decrypt, or otherwise attempt to discover the
                source code or non-literal aspects (such as the underlying ideas, algorithms, structure, sequence, organization, and interfaces) of, the Platform; (d) remove, alter, or conceal, in
                whole or in part, any copyright, trademark, or other proprietary rights notice or legend displayed or contained on or in the Platform; (e) circumvent, disable or otherwise interfere
                with security-related or technical features or protocols of the Platform; (f) make a derivative work of the Platform, or use the Platform to develop any service or Experience that is
                the same as (or substantially similar to or competitive with) the Platform; (g) publish or transmit any robot, virus, malware, Trojan horse, spyware, or similar malicious item
              </p>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "52pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                intended (or that has the potential) to damage or disrupt the Platform; (i) take any action that imposes or may impose (at Townish's sole discretion) an unreasonable or
                disproportionately large load on the Platform’s infrastructure, or otherwise interfere (or attempt to interfere) with the integrity or proper working of the Platform; and/or (j) use
                the Platform to infringe, misappropriate or violate any third party's Intellectual Property Rights, or any Law.
              </p>
            </li>
            <li data-list-text="(f)">
              <p
                style={{
                  paddingLeft: "45pt",
                  textIndent: "-10pt",
                  textAlign: "left",
                }}
              >
                ​
              </p>
            </li>
            <li data-list-text="(g)">
              <p
                style={{
                  paddingLeft: "52pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Vendor's full compliance with the above restrictions is a condition to the License; <i>provided however, </i>that such limitations shall not apply to the extent expressly permitted
                otherwise in this Agreement. Vendor acknowledges that the Platform contains information and materials that are confidential and proprietary to Townish (and may even constitute
                Townish's trade secrets), and therefore Vendor agrees that a breach or threatened breach of this Section <i>(License Restrictions) </i>may cause Townish to suffer irreparable harm or
                damage for which monetary damages will be inadequate, and accordingly, if Townish seeks an injunction, specific performance, or other equitable relief to enforce any provision under
                this Section, Townish shall not be required to post a bond or to prove the likelihood of irreparable harm.
              </p>
            </li>
            <li data-list-text="(h)">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-12pt",
                  textAlign: "left",
                }}
              >
                ​
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text={5}>
          <p className="s1" style={{ paddingLeft: "14pt", textIndent: "-8pt", textAlign: "left" }}>
            {" "}
            &nbsp;&nbsp; ​ THIRD PARTY SOFTWARE
          </p>
          <ol id="l5">
            <li data-list-text="(i)">
              <p
                style={{
                  paddingLeft: "16pt",
                  textIndent: "-10pt",
                  textAlign: "left",
                }}
              >
                ​
              </p>
              <p
                style={{
                  paddingLeft: "52pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                (j) The Platform may include third party software components that are subject to open source and/or pass-through commercial licenses and/or notices ("<b>Third Party Software</b>" and "
                <b>Third Party Software Terms and Notices</b>", respectively). Townish may make available a current list of such Third Party Software and Third Party Software Terms and Notices in the
                Documentation. Townish will reasonably comply with any valid written request submitted by Vendor to Townish for exercising Townish's rights under such Third Party Software Terms and
                Notices. Vendor acknowledges that Vendor's use of the Platform is also governed by such Third Party Software Terms and Notices, and that to the extent of any conflict between this
                Agreement and any Third Party Software Terms and Notices, the latter shall control. Any undertakings, representations, warranties, guarantees, conditions, indemnities or other
                commitments made by Townish in this Agreement concerning the Platform (if any), are made by Townish and not by any authors, licensors, or suppliers of, or contributors to, such Third
                Party Software. Notwithstanding the foregoing sentence or anything in this Agreement to the contrary, Townish does not make any representation, warranty, guarantee, or condition, and
                does not undertake any defense or indemnification with respect to any Third Party Software.
              </p>
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                (k)
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text={6}>
          <p className="s1" style={{ paddingLeft: "14pt", textIndent: "-8pt", textAlign: "left" }}>
            {" "}
            &nbsp;&nbsp; ​ THIRD PARTY CONTENT AND SOURCES
          </p>
          <p style={{ paddingLeft: "70pt", textIndent: "0pt", textAlign: "left" }}>a.</p>
          <p
            style={{
              paddingLeft: "88pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            b. The Platform may present, or otherwise allow Vendor to view, access, link to, and/or interact with, Content from third parties and other sources that are not owned or controlled by
            Townish, such as Third Party Integrators (such Content, "<b>Third Party Content</b>"). The Platform may also enable Vendor to communicate with the related third parties. The display or
            communication to Vendor of such Third Party Content does not (and shall not be construed to) in any way imply, suggest, or constitute any sponsorship, endorsement, or approval by Townish
            of such Third Party Content or third party, nor any affiliation between Townish and such third party.
          </p>
          <p style={{ paddingLeft: "34pt", textIndent: "0pt", textAlign: "left" }}>(l)</p>
          <p style={{ paddingLeft: "52pt", textIndent: "-18pt", textAlign: "left" }}>
            (m) Townish does not assume any responsibility or liability for Third Party Content, or any third party's terms of use, privacy policies, actions, omissions, or practices.
          </p>
          <p style={{ paddingLeft: "34pt", textIndent: "0pt", textAlign: "left" }}>(n)</p>
        </li>
        <li data-list-text={7}>
          <p className="s1" style={{ paddingLeft: "14pt", textIndent: "-8pt", textAlign: "left" }}>
            {" "}
            &nbsp;&nbsp; ​ VENDOR CONTENT
          </p>
          <p style={{ paddingLeft: "34pt", textIndent: "0pt", textAlign: "left" }}>(o)</p>
          <ol id="l6">
            <li data-list-text="7.1.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>
                  <b>&nbsp; ​ </b>
                </u>
                <u>Responsibility</u>. As between Vendor and Townish, Vendor is solely responsible and liable for its Vendor Content, for the consequences of publishing it (including, but not limited
                to, for obtaining any consents from individuals appearing therein), and for the way in which it is used
              </p>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                by others, and Vendor agrees to hold Townish harmless, and expressly releases Townish, from any and all liability arising from such Vendor Content. Vendor hereby represents and
                warrants that: (a) its Vendor Content does not, and will not, infringe, misappropriate, or violate any third party's Intellectual Property Rights, or any Law; (b) its Vendor Content is
                not, and will not be, disparaging, libelous, threatening, offensive, harassing, deceptive, abusive, promoting of violence, illegal drugs, illegal arms trafficking, or illegal gambling,
                and does not, and will not, contain obscenity or pornography, create any risk to a person's safety or health, impersonate another person, compromise national security, or interfere
                with an investigation by law enforcement; (c) its Vendor Content does not, and will not, contain any robot, virus, malware, Trojan horse, spyware, or similar malicious item intended
                (or that has the potential) to damage or disrupt the Platform; (d) its Vendor Content is correct, complete, accurate, and up to date; and (e) Vendor has obtained, and will maintain
                during and (to the extent applicable) after any termination of this Agreement, any and all licenses, permissions, consents, approvals, and authorizations required to enter into this
                Agreement, to contract with and deliver to Buyers and to grant the Townish License (defined below).
              </p>
              <p
                className="s1"
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                a. &nbsp;&nbsp;&nbsp;
              </p>
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "49pt",
                  textAlign: "justify",
                }}
              >
                <u>
                  <b>b. &nbsp;&nbsp;&nbsp;&nbsp; </b>
                </u>
                Vendor acknowledges that when accessing and using the Platform: (i) it will be exposed to Content from a variety of other users, and that Townish is not responsible for the accuracy,
                usefulness, safety, or Intellectual Property Rights of, or relating to, such Content; and
              </p>
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                (b) Vendor may be exposed to Content that is inaccurate, offensive, indecent, or objectionable. Vendor hereby irrevocably waives any legal or equitable rights or remedies it may have
                against Townish with respect to (i) and (ii) in this paragraph.
              </p>
              <p
                className="s4"
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                c. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p
                className="s4"
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                d. &nbsp;&nbsp;&nbsp;
              </p>
            </li>
            <li data-list-text="7.2.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>
                  <b>&nbsp; ​ </b>
                </u>
                <u>No Obligation by Townish</u>. Townish has no obligation to accept, display, review or maintain any Vendor Content. Townish may, without notice, edit, replace and/or delete your
                Vendor Content, including, but not limited to, if Townish: (a) determines that Vendor is in breach of any provision of this Agreement or has violated (or are suspected by Townish of
                violating) any Law; and/or (b) deems it helpful or reasonably necessary to comply with any applicable Law, legal process, or governmental request, to enforce the Agreement (including
                investigations of potential violations thereof), to detect, prevent, or otherwise address fraud, security issues, and/or to protect against harm to the rights, property or safety of
                Townish, its users, or the public.
              </p>
              <p
                className="s1"
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                e. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
            </li>
            <li data-list-text="7.3.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                <u>No&nbsp; Endorsement</u>. Vendor agrees not to represent or suggest, directly or indirectly, the endorsement of any Vendor Content by Townish or any other user of the Platform.
              </p>
              <p
                className="s1"
                style={{
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                (p) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text={8}>
          <p className="s1" style={{ paddingLeft: "14pt", textIndent: "-8pt", textAlign: "left" }}>
            {" "}
            &nbsp;&nbsp; ​ EXPERIENCES
          </p>
          <p style={{ paddingLeft: "34pt", textIndent: "0pt", textAlign: "left" }}>(q)</p>
          <ol id="l7">
            <li data-list-text="8.1.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>&nbsp; ​ Experiences</u>. In respect of each Experience listed on the Platform, Vendor hereby represents and warrants that: (a) Vendor has obtained all licenses, permissions,
                consents, approvals, and authorizations required to sell the Experience and to perform all its obligations under this Agreement, and that its listing does not, and will not, infringe,
                misappropriate, or violate any third party's Intellectual Property Rights, or any Law; (b) it has, and shall retain for the term of the Agreement, qualified and dedicated staff with
                the appropriate level of expertise, skills and knowledge to perform the obligations and meet the requirements contemplated in the Agreement in a timely and diligent manner; (c) the
                Experience matches the corresponding Experience description (and other Vendor Content) associated with such Experience as described in the Order Documentation; (d) the applicable
                details and any specific terms and conditions for each Experience will be clearly displayed and communicated to Townish and the applicable Buyer, and shall include all the information
                relating to the Experience (including photos and descriptions), its amenities and services and the rooms/event spaces available for reservation, details of the rates (including all
                applicable taxes, levies, surcharges and fees) and availability; (e) to the extent Vendor has access to the Platform, Vendor shall update the Experience's information on weekly basis
                and, in any event, as soon as a substantial change is
              </p>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                feasible, through the agreed channels, i.e., the Vendor account or via email; (f) the Experience holds and complies with all permits, licenses and other governmental authorizations and
                requirements necessary for conducting, carrying out and continuing its operations and business and making the Experience available for reservation on the Platform (including for short
                term stay); and (g) if any Experience becomes part of a package or linked travel arrangement as defined by the Package Travel Directive (EU) 2015/2302, then Vendor will be the
                organizer of the package and will be solely responsible for the provision of the package or linked travel arrangements and for any obligations attached to the package or linked travel
                arrangement including those imposed by the Package Travel Directive or similar local legislation. Upon request by Townish, Vendor will promptly furnish Townish with appropriate
                information and documentation demonstrating Vendor's compliance with the foregoing representations and warranties.
              </p>
              <p
                className="s4"
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                a. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
            </li>
            <li data-list-text="8.2.">
              <p
                style={{
                  paddingLeft: "26pt",
                  textIndent: "-21pt",
                  textAlign: "justify",
                }}
              >
                <u>Overbooking</u>. Without derogating from the above, in the event that Buyer purchased an Experience that is not available, Vendor shall provide a suitable alternative Experience of
                an equal or better standard, cover any transportation or other costs that may become due as a result of providing a substitute Experience and/or cover any reimbursement or compensation
                for any reasonable costs or expenses that the Buyer or Townish may incur.
              </p>
              <p
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                b.
              </p>
            </li>
            <li data-list-text="8.3.">
              <p
                style={{
                  paddingLeft: "26pt",
                  textIndent: "-21pt",
                  textAlign: "justify",
                }}
              >
                <u>Buyers' Complaints</u>. Vendor shall deal directly with Buyers' complaints. Townish shall assume no responsibility for any claims or complaints related to the Experiences. Townish
                may at its sole discretion offer to act as an intermediary between Vendor and Buyer(s) or assist Buyer(s) in its communications with or against the Vendor. In such event, Townish may
                also offer an alternative Experience to Buyer at its sole discretion.
              </p>
              <p
                style={{
                  paddingLeft: "77pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                c.
              </p>
            </li>
            <li data-list-text="8.4.">
              <p
                style={{
                  paddingLeft: "26pt",
                  textIndent: "-21pt",
                  textAlign: "justify",
                }}
              >
                <u>No Obligation by Townish</u>. Townish has no obligation to accept, display, review or maintain any of Vendor's Experiences. Townish may, without notice, edit, replace and/or delete
                Vendor's Experience listings, including, but not limited to, if Townish: (a) determines that Vendor is in breach of any provision of this Agreement or has violated (or are suspected by
                Townish of violating) any Law; and/or (b) deems it helpful or reasonably necessary to comply with any applicable Law, legal process, or governmental request, to enforce the Agreement
                (including investigations of potential violations thereof), to detect, prevent, or otherwise address fraud, security issues, and/or to protect against harm to the rights, property or
                safety of Townish, its users, or the public. Additionally, Townish may use mechanisms that rate or review, or allow Buyers to rate or review, Vendor’s Experiences and Townish may make
                these ratings publically available. Townish will have no liability to Vendor for the content or accuracy of any ratings or reviews.
              </p>
              <p
                style={{
                  paddingLeft: "77pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                d.
              </p>
              <p
                className="s1"
                style={{
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                (r) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text={9}>
          <p className="s1" style={{ paddingLeft: "14pt", textIndent: "-8pt", textAlign: "left" }}>
            {" "}
            &nbsp;&nbsp; ​ ORDERS AND PAYMENT
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <ol id="l8">
            <li data-list-text="9.1.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Orders</u>. As between Vendor and Townish, Vendor shall be the "Vendor of record" for Orders, and shall be solely responsible for invoicing the Buyer and charging any applicable
                taxes under an Order. The Platform will generate an automatic email message to each Buyer, confirming receipt of the Buyer's Order, and the Platform will thereafter transmit to Vendor
                the Order information ("
                <b>Order Transmittance</b>"). Vendor must accept Orders issued in accordance with this Agreement and shall not charge any additional fees, extras or surcharges (other than agreed
                rate). The Vendor shall comply with PCI compliance criteria and validation processes as set forth in the Payment Card Industry in accordance with applicable law and regulation. Townish
                will collect all proceeds paid by a Buyer under an Order (the "<b>Proceeds</b>").
              </p>
              <p
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                a.
              </p>
            </li>
            <li data-list-text="9.2.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Pricing</u>. Vendor will ensure at all times that the purchase price and every other term of offer and/or sale of the Experiences (including any "low price" guarantee, rebate or
                discount, any free or discounted Experiences or other benefit available as a result of purchasing one or more other Experiences, and terms of applicable refund policies) is at least as
                favorable to Buyers of the
              </p>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Platform as the most favorable terms upon which an Experience is offered and/or sold via Vendor’s website or by Vendor on any other third party Platform.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="9.3.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Fulfillment.</u> Following Order Transmittance, Vendor shall be solely responsible and liable for fulfillment of the Order as it has been reserved by Buyer.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="9.4.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>&nbsp; ​ Cancellations and Refunds</u>. Vendor will maintain a policy on Order cancellations and refunds (collectively, the " <b>Vendor Refunds Policy</b>") in accordance with
                applicable Law. Vendor shall, upon request from Townish, furnish Townish with a copy of the Vendor Refunds Policy, and shall in good faith consider any suggested changes thereto
                provided by Townish. Vendor shall not make any modifications to its then-current Vendor Refunds Policy without giving Townish at least fourteen (14) days' prior written notice. Vendor
                shall be solely responsible and liable for implementing its Refunds Policy, and for determining if a Buyer is entitled to a refund. If Vendor determines that a Buyer is entitled to a
                cash refund (whether full or partial), Vendor shall notify Townish. In the event Vendor does not have such policy, the Townish Refunds Policy, which can be made available upon request,
                shall apply.
              </p>
              <p
                className="s4"
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                b. &nbsp;&nbsp;&nbsp;
              </p>
            </li>
            <li data-list-text="9.5.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Payment</u>. Townish shall pay Vendor the agreed price for the applicable Experience. For clarity, Townish shall not be required to make payment until it has received the applicable
                payment from Buyers. The payment by Townish to Vendor shall be deemed inclusive of any tax or other governmental charge. For clarity, Vendor shall be responsible for any tax payments
                as requested under the applicable laws. Townish shall also be entitled to withhold from any payments any and all amounts as may be required from time to time under applicable Law.
              </p>
              <p
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                c.
              </p>
              <p
                className="s1"
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                d. &nbsp;&nbsp;&nbsp;
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text={10}>
          <p className="s1" style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}>
            &nbsp; ​ FEATURES
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>(s)</p>
          <ol id="l9">
            <li data-list-text="10.1.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>General</u>. All references herein to the "Platform" shall be deemed to include its Features as well. Townish reserves the right to remove, modify, and/or add Features at any time,
                without any notice or obligation to Vendor, and for any reason whatsoever. Some Features may in any event be limited, suspended or restricted by geography, volume, duration or any
                other criteria decided by Townish in its sole discretion. Moreover, if Townish determines that Vendor is in breach of any provision of this Agreement, Townish reserves the right to
                block Vendor from certain Features. Townish makes Features available because it believes it enhances the user experience of the Platform; but Vendor acknowledges and agrees that just
                because Townish makes a Feature available, it does not mean Townish endorses, or can otherwise control, every manner in which such Feature is used. A new or modified Feature may be
                accompanied by separate or additional terms and conditions, in which case such terms will (as determined in such terms and conditions) apply instead of, or in addition to, this
                Agreement. Townish may in its sole discretion charge for new Features.
              </p>
              <p
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                a.
              </p>
            </li>
            <li data-list-text="10.2.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Beta Experiences</u>. Townish may from time to time make available, in "beta" mode, new versions of the Platform and/or new Features that are still undergoing internal development
                and testing (each, a "<b>Beta Experience</b>"). Beta Experiences may be time-limited, feature-limited, and/or functionality-limited. Townish may also decide to only make a Beta
                Experience available to closed list of Vendor s or users. If Townish makes a Beta Experience available to Vendor, Vendor may use it on the following conditions: (i) Vendor only uses it
                for evaluation purposes;
              </p>
              <ol id="l10">
                <li data-list-text="(ii)">
                  <p
                    style={{
                      paddingLeft: "34pt",
                      textIndent: "0pt",
                      textAlign: "justify",
                    }}
                  >
                    Vendor complies with any specific guidelines issued by Townish in respect of the Beta Experience, which may include a requirement that Vendor provide Feedback (defined below) and
                    participate in surveys about the Beta Experience; and (iii) Vendor does not publicize the fact that there is a Beta Experience, and does not show, display, or otherwise make
                    available the Beta Experience to any other person (which includes, but is not limited to, making such disclosures to traditional or social media). For the avoidance of doubt, the
                    provisions of this Agreement that apply to the Platform (such as the <i>Usage Restrictions </i>in Section 4, and the <i>Disclaimers </i>in Section 13) shall also apply to Beta
                    Experiences.
                  </p>
                </li>
              </ol>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                (t)
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text={11}>
          <p className="s1" style={{ paddingLeft: "18pt", textIndent: "-13pt", textAlign: "left" }}>
            &nbsp; ​ UPDATES
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>(u)</p>
          <p
            style={{
              paddingLeft: "52pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            (v) All references herein to the "Platform" shall be deemed to include any Updates made available as well. Townish may from time to time provide Updates, but shall not be under any
            obligation to do so. Such Updates will be supplied according to whatever then-current policies Townish may have in place, and some Updates may in any event be limited, suspended or
            restricted by geography, volume, duration or any other criteria decided by Townish in its sole discretion. Some Updates may include automatic updating or upgrading without any notice or
            obligation to Vendor, and Vendor consents to any such automatic updating or upgrading of the Platform. In some cases, Vendor may be required to install the Update manually. An Update may
            be accompanied by separate or additional terms and conditions, in which case such terms will (as determined in such terms and conditions) apply instead of, or in addition to, this
            Agreement. Townish may in its sole discretion charge for Updates. Townish is under no obligation to provide any technical support for the Platform.
          </p>
          <p style={{ paddingLeft: "34pt", textIndent: "0pt", textAlign: "left" }}>(w)</p>
        </li>
        <li data-list-text={12}>
          <p className="s1" style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}>
            &nbsp; ​ OWNERSHIP AND CONTENT LICENSE
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>(x)</p>
          <ol id="l11">
            <li data-list-text="12.1.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Platform</u>. Vendor hereby acknowledges that the Platform is or may be protected by Intellectual Property Rights (and similar) Laws, treaties, and conventions. Any rights not
                expressly granted herein are hereby reserved by Townish and its licensors. As between Vendor and Townish (and except for the License), Townish is the sole and exclusive owner of all
                Intellectual Property Rights in and to the Platform, the Documentation, and all Content generated or otherwise provided or appearing on or in the Platform (except to the extent such
                Content constitutes Vendor's Vendor Content). For the avoidance of doubt, "Townish", and "Townish.ca" (and their respective logos) are trademarks of Townish, and all other trademarks
                appearing within the Platform or Documentation are the trademarks of their respective owners.
              </p>
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                (y)
              </p>
            </li>
            <li data-list-text="12.2.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                <u>Vendor Content Ownership and Licenses</u>. As between Vendor and Townish, Vendor is the sole and exclusive owner of its Vendor Content. Notwithstanding such ownership:
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
              <ol id="l12">
                <li data-list-text="(A)">
                  <p
                    style={{
                      paddingLeft: "62pt",
                      textIndent: "-21pt",
                      textAlign: "justify",
                    }}
                  >
                    Vendor hereby grants Townish a worldwide, assignable, non-exclusive, royalty-free, fully paid-up, sublicensable (through multiple tiers of sublicensees), irrevocable and perpetual
                    license, in any media format and through any media channels (now known or hereafter developed):
                  </p>
                  <ol id="l13">
                    <li data-list-text="a.">
                      <p
                        style={{
                          paddingLeft: "82pt",
                          textIndent: "1pt",
                          textAlign: "justify",
                        }}
                      >
                        (a) to access and use Vendor Content to perform under this Agreement, including without limitation to provide Vendor with the functionality of the Platform and any technical
                        support; and
                      </p>
                    </li>
                    <li data-list-text="b.">
                      <p
                        style={{
                          paddingLeft: "82pt",
                          textIndent: "1pt",
                          textAlign: "justify",
                        }}
                      >
                        (b) to access and use Vendor Content on an aggregated or otherwise anonymous basis for improving the Platform, marketing and promoting the Platform, analytics, and generally
                        research and development
                      </p>
                    </li>
                    <li data-list-text="c.">
                      <p
                        style={{
                          paddingLeft: "62pt",
                          textIndent: "49pt",
                          textAlign: "justify",
                        }}
                      >
                        ((a) and (b) collectively, the "<b>Townish License</b>"). The Townish License shall survive any termination of this Agreement.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="(B)">
                  <p
                    style={{
                      paddingLeft: "62pt",
                      textIndent: "-21pt",
                      textAlign: "justify",
                    }}
                  >
                    To the extent Vendor has any Moral Rights in or to any of its Vendor Content, Vendor hereby irrevocably waives (and agrees never to assert) against Townish, Townish's licensors and
                    suppliers, and/or any other Platform user, any such Moral Rights.
                  </p>
                  <p
                    style={{
                      paddingLeft: "83pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    d.
                  </p>
                </li>
              </ol>
            </li>
            <li data-list-text="12.3.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Feedback</u>. If Vendor provides Townish with any ideas, suggestions, or similar feedback about performance of the Platform and/or for improving the Platform ("<b>Feedback</b>"),
                Vendor hereby grants Townish a worldwide, assignable, non-exclusive, royalty-free, fully paid-up, sublicensable (through multiple tiers of sublicensees), irrevocable, and perpetual
                license, in any media format and through any media channels (now known or hereafter developed), to access, use, reproduce, distribute, publish, broadcast, make available to the public,
                modify, adapt, edit, create derivative works of, publicly display, publically perform, and otherwise commercially
              </p>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                exploit such Feedback (the "<b>Feedback License</b>"). Vendor hereby represents and warrants that:
              </p>
              <ol id="l14">
                <li data-list-text="(A)">
                  <p
                    style={{
                      paddingLeft: "34pt",
                      textIndent: "0pt",
                      textAlign: "justify",
                    }}
                  >
                    its Feedback does not, and will not, infringe, misappropriate or violate any third party's Intellectual Property Rights, or any Law; and (B) it has obtained, and will maintain
                    during and after any termination of this Agreement, all licenses, permissions, consents, approvals, and authorizations required to grant the Feedback License. The Feedback License
                    shall survive any termination of this Agreement.
                  </p>
                  <p
                    style={{
                      paddingLeft: "83pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    e.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li data-list-text={13}>
          <p className="s5" style={{ paddingLeft: "34pt", textIndent: "-28pt", textAlign: "left" }}>
            <span className="s1">ONLINE MARKETING AND PAY-PER-CLICK ADVERTISING</span>.
          </p>
          <p style={{ paddingLeft: "34pt", textIndent: "0pt", textAlign: "left" }}>(z)</p>
          <p
            style={{
              paddingLeft: "52pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            (aa)Vendor grants to Townish a non-exclusive, non-transferable, non-sublicensable, worldwide, royalty-free license during the Term to use (a) Vendor’s name and trademarks to identify
            Vendor as a vendor on Townish's Platform; and (b) Vendor’s name and trademarks as part of any marketing activities under this Agreement, including as part of Vendor's email marketing
            and/or pay per click (PPC) advertising campaigns.
          </p>
          <p style={{ paddingLeft: "34pt", textIndent: "0pt", textAlign: "left" }}>(bb)</p>
          <p style={{ paddingLeft: "34pt", textIndent: "0pt", textAlign: "left" }}>(cc)</p>
        </li>
        <li data-list-text={14}>
          <p className="s1" style={{ paddingLeft: "34pt", textIndent: "-28pt", textAlign: "left" }}>
            CONFIDENTIALITY.
          </p>
          <p style={{ paddingLeft: "34pt", textIndent: "0pt", textAlign: "left" }}>(dd)</p>
          <p
            style={{
              paddingLeft: "52pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            (ee)Each Party (the "<b>Recipient</b>") may have access to certain non-public or proprietary information and materials of the other Party (the "<b>Discloser</b>"), whether in tangible or
            intangible form ("
            <b>Confidential Information</b>"). The Parties agree that each of the provisions of this Agreement (including without limitation pricing and payment terms) are the Confidential Information
            of Townish. Confidential Information shall not include information and material which: (a) at the time of disclosure by Discloser to Recipient hereunder, is in the public domain; (b) after
            disclosure by Discloser to Recipient hereunder, becomes part of the public domain through no fault of the Recipient; (c) was rightfully in the Recipient's possession at the time of
            disclosure by the Discloser hereunder, and which is not subject to prior continuing obligations of confidentiality; (d) is rightfully disclosed to the Recipient by a third party having the
            lawful right to do so; or (e) independently developed by the Recipient without use of, or reliance upon, Confidential Information received from the Discloser. The Recipient shall not
            disclose the Discloser's Confidential Information to any third party, except to its employees, advisers, agents and investors, subject to substantially similar written confidentiality
            undertakings). Recipient shall take commercially reasonable measures, at a level at least as protective as those taken to protect its own Confidential Information of like nature (but in no
            event less than a reasonable level), to protect the Discloser's Confidential Information within its possession or control, from disclosure to a third party. The Recipient shall use the
            Discloser's Confidential Information solely for the purposes expressly permitted under this Agreement. In the event that Recipient is required to disclose Confidential Information of the
            Discloser pursuant to any Law, regulation, or governmental or judicial order, the Recipient will (a) promptly notify Discloser in writing of such Law, regulation or order, (b) reasonably
            cooperate with Discloser in opposing such disclosure, (c) only disclose to the extent required by such Law, regulation or order (as the case may be). Upon termination of this Agreement, or
            otherwise upon written request by the Discloser, the Recipient shall promptly return to Discloser its Confidential Information (or if embodied electronically, permanently erase it), and
            certify compliance writing.
          </p>
          <p style={{ paddingLeft: "83pt", textIndent: "0pt", textAlign: "left" }}>a.</p>
        </li>
        <li data-list-text={15}>
          <p className="s1" style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}>
            {" "}
            &nbsp;&nbsp; ​ AUDIT/BOOKS AND RECORD.
          </p>
          <p className="s1" style={{ paddingLeft: "34pt", textIndent: "0pt", textAlign: "left" }}>
            (ff)&nbsp;
          </p>
          <p style={{ paddingLeft: "52pt", textIndent: "-18pt", textAlign: "left" }}>
            (gg) Vendor shall maintain in the ordinary course of business appropriate records in connection with this Agreement throughout the Term of this Agreement and for at least two
          </p>
          <p style={{ paddingLeft: "52pt", textIndent: "0pt", textAlign: "justify" }}>
            (2) years thereafter. Vendor agrees that Townish may audit such records to determine Vendor’s compliance with this Agreement. Any such audit shall be require reasonable notice and be
            performed during Vendor’s normal business hours.
          </p>
          <p style={{ paddingTop: "4pt", textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text={16}>
          <p className="s1" style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}>
            &nbsp; ​ DISCLAIMERS
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>(hh)</p>
          <ol id="l15">
            <li data-list-text="16.1.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                THE PLATFORM, DOCUMENTATION, AND ANY CONTENT WHATSOEVER ARE PROVIDED AND MADE AVAILABLE TO VENDOR ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITH ALL FAULTS, AND WITHOUT ANY
                REPRESENTATION, WARRANTY, GUARANTEE OR CONDITION OF ANY KIND WHATSOEVER, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF VENDOR ABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, QUIET POSSESSION, TITLE, QUALITY OF SERVICE, NON-INFRINGEMENT, OR THAT OTHERWISE ARISE FROM A COURSE OF PERFORMANCE OR DEALING,
                OR USAGE OF TRADE, ALL OF WHICH ARE HEREBY DISCLAIMED BY TOWNISH AND ITS LICENSORS AND SUPPLIERS.
              </p>
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                (ii)
              </p>
            </li>
            <li data-list-text="16.2.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                IN ADDITION, NEITHER TOWNISH NOR ITS LICENSORS OR SUPPLIERS MAKE ANY REPRESENTATION, WARRANTY, GUARANTEE OR CONDITION:
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
              <ol id="l16">
                <li data-list-text="(A)">
                  <p
                    style={{
                      paddingLeft: "62pt",
                      textIndent: "-21pt",
                      textAlign: "justify",
                    }}
                  >
                    REGARDING THE EFFECTIVENESS, USEFULNESS, RELIABILITY, AVAILABILITY, TIMELINESS, ACCURACY, OR COMPLETENESS OF THE PLATFORM, DOCUMENTATION, OR SUCH CONTENT;
                  </p>
                </li>
                <li data-list-text="(B)">
                  <p
                    style={{
                      paddingLeft: "62pt",
                      textIndent: "-21pt",
                      textAlign: "justify",
                    }}
                  >
                    THAT VENDOR'S USE OF, OR RELIANCE UPON, THE PLATFORM, DOCUMENTATION, OR SUCH CONTENT WILL MEET VENDOR'S REQUIRMENTS OR EXPECTATIONS;
                  </p>
                </li>
                <li data-list-text="(C)">
                  <p
                    style={{
                      paddingLeft: "62pt",
                      textIndent: "-21pt",
                      textAlign: "justify",
                    }}
                  >
                    THAT THE PLATFORM, DOCUMENTATION, OR SUCH CONTENT WILL BE UNINTERRUPTED, SECURE, ERROR-FREE OR VIRUS-FREE, OR THAT DEFECTS IN THE TOWNISHPLACE WILL BE CORRECTED; OR
                  </p>
                </li>
                <li data-list-text="(D)">
                  <p
                    style={{
                      paddingLeft: "62pt",
                      textIndent: "-21pt",
                      textAlign: "justify",
                    }}
                  >
                    REGARDING THE SATISFACTION OF, OR COMPLIANCE WITH, ANY GOVERNMENT REGULATIONS OR STANDARDS.
                  </p>
                  <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                  </p>
                </li>
              </ol>
            </li>
            <li data-list-text="16.3.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                Some jurisdictions' Laws do not allow the disclaimer of certain implied warranties or conditions, and to the extent applicable to Vendor, Townish limits the duration of such warranties
                and conditions to the duration of ninety (90) days from the Effective Date.
              </p>
              <p
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                a.
              </p>
            </li>
            <li data-list-text="16.4.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                VENDOR ACKNOWLEDGES AND AGREES THAT THIS SECTION <i>(DISCLAIMER) </i>IS AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN VENDOR AND TOWNISH.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text={17}>
          <p className="s1" style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}>
            &nbsp; ​ LIMITATION OF LIABILITY
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>(jj)</p>
          <ol id="l17">
            <li data-list-text="17.1.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                IN NO EVENT SHALL TOWNISH, OR ANY OF ITS LICENSORS OR SUPPLIERS BE LIABLE UNDER, OR OTHERWISE IN CONNECTION WITH, THIS AGREEMENT, FOR:
              </p>
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                (kk)
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
              <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>(ll)</p>
              <ol id="l18">
                <li data-list-text="(A)">
                  <p
                    style={{
                      paddingLeft: "41pt",
                      textIndent: "-35pt",
                      textAlign: "left",
                    }}
                  >
                    ANY CONSEQUENTIAL, INDIRECT, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES;
                  </p>
                </li>
                <li data-list-text="(B)">
                  <p
                    style={{
                      paddingLeft: "41pt",
                      textIndent: "-35pt",
                      textAlign: "left",
                    }}
                  >
                    ANY LOSS OF PROFITS, LOSS OF BUSINESS OPPORTUNITY, LOSS OF REVENUE, OR LOSS OF ANTICIPATED SAVINGS;
                  </p>
                </li>
                <li data-list-text="(C)">
                  <p
                    style={{
                      paddingLeft: "41pt",
                      textIndent: "-35pt",
                      textAlign: "left",
                    }}
                  >
                    ANY LOSS OF, OR DAMAGE TO, DATA, REPUTATION, OR GOODWILL; AND/OR
                  </p>
                </li>
                <li data-list-text="(D)">
                  <p
                    style={{
                      paddingLeft: "41pt",
                      textIndent: "-35pt",
                      textAlign: "left",
                    }}
                  >
                    THE COST OF PROCURING ANY SUBSTITUTE GOODS OR SERVICES.
                  </p>
                </li>
              </ol>
            </li>
            <li data-list-text="17.2.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                THE COMBINED AGGREGATE LIABILITY OF TOWNISH UNDER, OR OTHERWISE IN CONNECTION WITH, THIS AGREEMENT SHALL NOT EXCEED THE LOWER OF: (A) Hundred U.S. DOLLARS (US $100), AND (B) THE AMOUNT
                ACTUALLY PAID BY VENDOR (IF ANY) TO TOWNISH DURING THE <b>THREE (3) MONTHS </b>
                IMMEDIATELY PRECEDING THE EVENT FIRST GIVING RISE TO LIABILITY.
              </p>
              <p
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                a.
              </p>
            </li>
            <li data-list-text="17.3.">
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                THE FOREGOING EXCLUSIONS AND LIMITATIONS SHALL APPLY: (A) TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW; (B) EVEN IF TOWNISH HAS BEEN ADVISED, OR SHOULD HAVE BEEN AWARE, OF THE
                POSSIBILITY OF LOSSES, DAMAGES, OR COSTS; (C) EVEN IF ANY REMEDY IN THIS AGREEMENT FAILS OF ITS ESSENTIAL PURPOSE; AND (D) REGARDLESS OF THE THEORY OR BASIS OF LIABILITY, INCLUDING
                WITHOUT LIMITATION BREACH OF WARRANTY, NEGLIGENCE, MISREPRESENTATION, STRICT LIABILITY, OR OTHER CONTRACT OR TORT LIABILITY.
              </p>
              <p
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                b.
              </p>
            </li>
            <li data-list-text="17.4.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                THE FOREGOING EXCLUSIONS AND LIMITATIONS SHALL <u>NOT</u> APPLY TO ANY TOWNISH LIABILITY ARISING: (A) FROM DEATH OR PERSONAL INJURY CAUSED BY NEGLIGENCE; OR (B) FROM FRAUD.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="17.5.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                Some jurisdictions' Laws do not allow the exclusion or limitation of incidental or consequential damages, or of other damages, and to the extent applicable to Vendor, such exclusions
                and limitations shall not apply.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="17.6.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                VENDOR ACKNOWLEDGES AND AGREES THAT THIS SECTION <i>(LIMITATION OF LIABILITY) </i>IS AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN VENDOR AND TOWNISH.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text={18}>
          <p className="s1" style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}>
            &nbsp; ​ INDEMNIFICATION
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>(mm)</p>
          <ol id="l19">
            <li data-list-text="a.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "49pt",
                  textAlign: "justify",
                }}
              >
                If any third party (including, but not limited to, a regulatory or governmental authority or a Third Party Integrator) brings any kind of demand, claim, suit, action or proceeding
                against Townish and/or any of our respective directors, officers, founders, employees, or representatives (each, an "<b>Indemnitee</b>"), and it is based upon or arises from:
              </p>
              <p
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                b.
              </p>
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                (nn) Vendor's use of the Platform;
              </p>
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                (oo) Any Experiences listed by Vendor on the Platform; and/or
              </p>
              <p
                style={{
                  paddingLeft: "90pt",
                  textIndent: "-35pt",
                  textAlign: "left",
                }}
              >
                (pp) Vendor's breach of any provision of this Agreement (including, but not limited to, any inaccuracy of any of Vendor's representations and warranties herein)
              </p>
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                a.
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingLeft: "113pt",
                  textIndent: "-29pt",
                  textAlign: "left",
                }}
              >
                (each of the foregoing, an "<b>Indemnity Claim</b>") then, upon written request by
              </p>
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                Townish (to be decided in its sole discretion), Vendor agrees to assume full control of the defense and settlement of the Indemnity Claim; <i>provided, however, </i>that (i) Townish
                reserves the right, at any time thereafter, to take over full or partial control of the defense and/or settlement of the Indemnity Claim, and in such cases Vendor agrees to reasonably
                cooperate with Townish's defense activities at Vendor 's own cost and expense; and (ii) Vendor shall not settle any Indemnity Claim, or admit to any liability thereunder, without the
                express prior written consent of the Indemnitee(s).
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingLeft: "91pt",
                  textIndent: "-7pt",
                  textAlign: "left",
                }}
              >
                ​
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "49pt",
                  textAlign: "justify",
                }}
              >
                In addition, and regardless of whether (or the extent to which) Vendor controlled or participated in the defense and/or settlement of an Indemnity Claim, Vendor agrees to indemnify and
                hold harmless the Indemnitee(s) for and against: (A) any costs and expenses (including reasonable attorneys' fees) incurred by the Indemnitee(s) in the defense of the Indemnity Claim;
                and (B) any amounts awarded against, or imposed upon, the Indemnitee(s) under such Indemnity Claim, or otherwise paid in settlement of the Indemnity Claim (including without limitation
                any fines or penalties).
              </p>
            </li>
            <li data-list-text="e.">
              <p
                style={{
                  paddingLeft: "91pt",
                  textIndent: "-7pt",
                  textAlign: "left",
                }}
              />
            </li>
          </ol>
        </li>
        <li data-list-text={19}>
          <p
            style={{
              paddingLeft: "34pt",
              textIndent: "-28pt",
              textAlign: "justify",
            }}
          >
            <u>
              <b>INSURANCE</b>
            </u>
            . Without derogating from Vendor’s liability pursuant to this Agreement and/or Vendor's liability under any applicable Law, during the Term of the Agreement, Vendor shall procure and
            maintain, at its own expense, all the necessary and customary insurances as needed to secure its obligations and potential liabilities under this Agreement and as are customary in
          </p>
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "34pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            the applicable industry in which Vendor operates. For the avoidance of doubts, the Vendor hereby confirms, that it understands Townish does not act as an insurer.
          </p>
          <p style={{ paddingLeft: "34pt", textIndent: "0pt", textAlign: "left" }}>(qq)</p>
        </li>
        <li data-list-text={20}>
          <p className="s6" style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}>
            &nbsp; ​ <span className="s1">BUYER DATA/ DATA PROTECTION CLAUSES</span>
          </p>
          <p style={{ paddingLeft: "83pt", textIndent: "0pt", textAlign: "left" }}>a.</p>
        </li>
        <li data-list-text={21}>
          <p className="s1" style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}>
            &nbsp; ​ TERM AND TERMINATION
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>(rr)</p>
          <ol id="l20">
            <li data-list-text="21.1.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                <u>Term</u>. This Agreement commences on the Effective Date and shall continue in full force and effect until terminated in accordance herewith (the "<b>Term</b>").
              </p>
              <p
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                a.
              </p>
            </li>
            <li data-list-text="21.2.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Termination for Convenience by Townish</u>. Townish reserves the right to immediately terminate this Agreement, or otherwise modify, suspend or discontinue Vendor's access to and
                use of the Platform (or any part thereof), for any reason whatsoever, at any time, upon 14 days prior notice to Vendor, and Vendor agrees that Townish shall have no liability to Vendor
                for any such termination, modification, suspension, or discontinuance.
              </p>
              <p
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                b.
              </p>
            </li>
            <li data-list-text="21.3.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Termination for Convenience by Vendor</u>. Vendor may terminate this Agreement at any time and for any reason, but only by giving Townish sixty (60) days' prior written notice, at{" "}
                <a href="mailto:vendors@townish.ca" className="s7" target="_blank" rel="noreferrer">
                  vendors@townish.ca
                </a>
                . If Vendor objects to any term or condition of this Agreement or any subsequent changes thereto, or becomes dissatisfied with the Platform in any way, Vendor's sole remedy is to
                terminate this Agreement. Such termination will not affect existing Orders.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="21.4.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Termination for Breach</u>. Each party may terminate this Agreement immediately upon written notice to the other party: (a) if the other party commits a material breach under this
                Agreement and fails to cure that breach within thirty (30) days after receipt of written notice specifying the material breach; and/or (b) if the other party is declared bankrupt by a
                judicial decision, or, in the event an involuntary bankruptcy action is filed against such other party, it has not taken, within sixty (60) days from service of such action to such
                party, any possible action under applicable law for such filed action to be dismissed.
              </p>
              <p
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                c.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text={22}>
          <p className="s1" style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}>
            &nbsp; ​ CONSEQUENCES OF TERMINATION; SURVIVAL
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>(ss)</p>
          <p
            style={{
              paddingLeft: "34pt",
              textIndent: "49pt",
              textAlign: "justify",
            }}
          >
            a. Upon termination of this Agreement, the License will automatically terminate and be deemed revoked, and Vendor shall immediately cease use of the Platform, except to the extent strictly
            necessary for Vendor to fulfil any Orders issued before the termination effective date. Sections 12 <i>(Ownership and Content License) </i>through 19 <i>(Miscellaneous) </i>shall survive
            termination of this Agreement, as shall any right, obligation or provision that is expressly stated to so survive, or that by its nature ought to survive. Termination shall not affect any
            rights and obligations accrued as of the effective date of termination.
          </p>
          <p style={{ paddingLeft: "83pt", textIndent: "0pt", textAlign: "left" }}>b.</p>
        </li>
        <li data-list-text={23}>
          <p className="s1" style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}>
            &nbsp; ​ GOVERNING LAW
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>(tt)</p>
          <p
            style={{
              paddingLeft: "34pt",
              textIndent: "49pt",
              textAlign: "justify",
            }}
          >
            a. This Agreement (including without limitation its validity and formation) shall be governed by, and construed in accordance with, the laws of the Province of Ontario, Canaa,, without
            regard to any conflicts of laws rules or principles.. Any claim, dispute or controversy under, or otherwise in connection with, this Agreement shall be subject to the exclusive
            jurisdiction and venue of the courts the Province of Ontario, Canada, and Vendor hereby irrevocably and unconditionally submits to the personal jurisdiction of such courts and waives any
            jurisdictional, improper venue, inconvenient forum, or other objections to such jurisdiction and venue. Furthermore, Vendor: (a){" "}
            <b>
              agrees that any proceedings to resolve or litigate any claim, dispute or controversy will be conducted solely on an individual basis (and not in any class action or class-wide
              proceeding), and that Vendor may initiate such proceedings only on its own behalf
            </b>
            ; (b) <b>hereby irrevocably waives the right to litigate such claims, disputes, or controversies in court before a jury</b>; and (c){" "}
            <b>
              agrees not to participate in claims, disputes, or controversies brought in an attorney general or representative capacity, or in consolidated claims, disputes, or controversies involving
              another person's Vendor Account.
            </b>
          </p>

          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text={24}>
          <p className="s1" style={{ paddingLeft: "19pt", textIndent: "-13pt", textAlign: "left" }}>
            &nbsp; ​ MISCELLANEOUS
          </p>
          <p style={{ paddingLeft: "34pt", textIndent: "0pt", textAlign: "left" }}>(uu)</p>
          <ol id="l21">
            <li data-list-text="24.1.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Assignment</u>. Townish may assign this Agreement (or any of its rights and/or obligations hereunder) without Vendor's consent, and without notice or obligation to Vendor. This
                Agreement is personal to Vendor, and Vendor shall not assign (or in any other way transfer) this Agreement (or any of its obligations or rights hereunder) without Townish's express
                prior written consent. Any prohibited assignment shall be null and void. Subject to the foregoing, this Agreement binds and benefits each party and its respective successors and
                assigns.
              </p>
              <p
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                a.
              </p>
            </li>
            <li data-list-text="24.2.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Severability</u>. If any provision of this Agreement is held by a court of competent jurisdiction to be invalid, illegal, or unenforceable, then: (a) the remaining provisions of
                this Agreement shall remain in full force and effect; and (b) such affected provision shall be ineffective solely as to such jurisdiction (and only to the extent and for the duration
                of such invalidity, illegality, or unenforceability), and shall be substituted (in respect of such jurisdiction) with a valid, legal, and enforceable provision that most closely
                approximates the original legal intent and economic impact of such provision.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="24.3.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Remedies</u>. Except as may be expressly stated otherwise in this Agreement, no right or remedy conferred upon or reserved by any party under this Agreement is intended to be, or
                shall be deemed, exclusive of any other right or remedy under this Agreement, at law or in equity, but shall be cumulative of such other rights and remedies.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="24.4.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Waiver</u>. No failure or delay on the part of any party in exercising any right or remedy under this Agreement shall operate as a waiver thereof, nor shall any single or partial
                exercise of any such right or remedy preclude any other or further exercise thereof or the exercise of any other right or remedy. Any waiver granted hereunder must be in writing (for
                waivers by Vendor, emails will be acceptable; for waivers by Townish, the writing must be duly signed by an authorized representative of Townish), and shall be valid only in the
                specific instance in which given.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="24.5.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Relationship</u>. The relationship of the parties is solely that of independent contractors. Nothing in this Agreement shall be deemed to create any employment, fiduciary, joint
                venture, agency or other relationship between the parties.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="24.6.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Publicity</u>. Vendor may not use Townish's name and logo on its website and in its promotional materials to state its relationship with Townish, without the prior written consent
                of Townish.
              </p>
              <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>(vv)</p>
            </li>
            <li data-list-text="24.7.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>No Storage</u>. The Platform is not intended to, and will not, operate as a data storage Experience or service, and Vendor agrees not to rely on the Platform for the storage of any
                Account Content whatsoever. Vendor is solely responsible and liable for the maintenance and backup of all Account Content. Notwithstanding the foregoing, Townish reserves the right to
                automatically download Account Content to Townish's cloud-based database(s).
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="24.8.">
              <p
                className="s4"
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                Notices . Vendor agrees that Townish may send Vendor notices by email, via Vendor's Vendor Account, by regular mail, and/or via postings on or through Vendor Centre. Except as stated
                otherwise in this Agreement or provided by the functionality of the Vendor Centre, Vendor agrees to send all notices to Townish, to{" "}
                <a href="mailto:Vendors@Townish.ca" className="s7" target="_blank" rel="noreferrer">
                  vendors@townish.ca
                </a>
                .
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="24.9.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>No Third Party Beneficiaries</u>. Except as otherwise expressly provided in this Agreement (such as Townish's licensors and suppliers, and Indemnitees), (i) there shall be no
                third-party beneficiaries of or under this Agreement, and (ii) a person who is not a party to this Agreement has no right to enforce or to enjoy the benefit of any term of this
                Agreement.
              </p>
              <p
                style={{
                  paddingLeft: "83pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                a.
              </p>
            </li>
            <li data-list-text="24.10.">
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "justify",
                }}
              >
                <u>Force Majeure</u>. Townish shall not be responsible for any failure to perform any obligation or provide any service hereunder because of any (a) act of God, (b) war, riot or civil
                commotion,
              </p>
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                (c) governmental acts or directives, strikes, pandemics, work stoppage, or equipment or facilities shortages, and/or (d) storms, snow or inclement weather, and/or (e) other similar
                cause beyond Townish's reasonable control. For the avoidance of doubt, any problems relating to the hosting of the Platform shall not be deemed within Townish's reasonable control.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="24.11.">
              <p
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                <u>Counterparts</u>. This Agreement may be executed in any number of counterparts, all of which taken together shall constitute one and the same instrument.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </>
  );
}
