import { CreateBookableInput, CreateVendorInput } from "../../API";
import BookableDetailElement from "./bookableDetail/bookableDetailElement";
import BookableDetailVendorElement from "./bookableDetail/bookableDetailVendorElement";
import VendorDetailElement from "./vendorDetailElement";

export function Preview(props: { vendor?: CreateVendorInput; bookable?: CreateBookableInput; showEditBtn?: boolean; setModalOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
  //vendor?: CreateVendorInput, vendorBookables?: CreateBookableInput[], bookable?: CreateBookableInput

  if (!props.vendor && !props.bookable) {
    return <></>;
  }

  if (props.vendor) {
    return <VendorDetailElement vendor={props.vendor} bookables={[]} setModalOpen={props.setModalOpen} />;
  }

  if (props.bookable) {
    return (
      <div>
        <BookableDetailElement bookable={props.bookable} showEditBtn={props.showEditBtn ? props.showEditBtn : false} setModalOpen={props.setModalOpen} />
        <BookableDetailVendorElement />
      </div>
    );
  }

  return <></>;
}
