import { Button, Grid, ListItem, ListItemIcon, ListItemText, Modal } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";

import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../App";
import ImageGallery from "../../../components/ImageGallery";
import { getS3ImagesInFolder, s3ImgParams } from "../../../Utils";

export default function BookableDetailVendorElement() {
  let globalVendor = useGlobalContext().vendor;
  const [imgs, setImgs] = useState<string[]>([]);
  const [valueProp, setValueProp] = useState<string[]>([]);
  const [galleryModalOpen, setGalleryModalOpen] = useState(false);

  useEffect(() => {
    if (globalVendor) {
      (async () => {
        const fetchedImages = await getS3ImagesInFolder(globalVendor.vendorImgPath);
        setImgs(fetchedImages);
      })();
    }

    if (globalVendor?.vendorValueProp) {
      let newValueProp = globalVendor.vendorValueProp.split("• ");
      setValueProp(newValueProp);
    }
  }, [globalVendor]);

  if (!globalVendor) {
    return <></>;
  }

  return (
    <>
      <Modal
        open={galleryModalOpen}
        onClose={(e) => {
          setGalleryModalOpen(false);
        }}
        onClick={(e) => {
          setGalleryModalOpen(false);
        }}
        sx={{
          background: "rgba(0, 0, 0, 0.5)",
          overflow: "scroll",
        }}
      >
        <div
          className="landing-div"
          style={{
            width: "90%",
            height: "auto",
            margin: "4rem auto",
            borderRadius: "15px",
            padding: "2rem",
          }}
        >
          <ImageGallery imgs={imgs} mainImg={globalVendor.vendorMainImg} />
        </div>
      </Modal>
      <h4 style={{ marginLeft: "10%", textAlign: "left" }}>Provided by:</h4>
      <Grid container item direction="row" style={{ width: "80%", margin: "1rem auto 0 auto" }}>
        <Grid container item xs={4}>
          <img
            src={s3ImgParams.s3ImgPath + globalVendor.vendorImgPath + "/" + encodeURIComponent(globalVendor.vendorMainImg)}
            alt="vendor"
            style={{
              borderRadius: "20px",
              width: "100%",
              maxHeight: "500px",
              objectFit: "cover",
            }}
            onClick={() => setGalleryModalOpen(true)}
          />
        </Grid>
        <Grid container item xs={8} direction="column" paddingLeft="1.5rem" textAlign={"left"}>
          <h5 style={{ margin: "1rem 0" }}>{globalVendor.vendorName}</h5>
          <div style={{ textAlign: "left" }}>
            {globalVendor.vendorSubheading && (
              <h5
                style={{
                  color: "var(--forest-green)",
                  margin: "0 0 .5rem 0",
                  fontSize: "22px",
                }}
              >
                {globalVendor.vendorSubheading}
              </h5>
            )}
            <Button variant="contained" color={"info"} sx={{ width: "40%" }}>
              Contact {globalVendor.vendorName}
            </Button>
          </div>
          <div style={{ textAlign: "left", margin: "0 auto 2rem auto" }}>
            <span className="desktop-body">{globalVendor.vendorDescription}</span>
          </div>
        </Grid>
      </Grid>

      {valueProp.length > 0 && (
        <Grid
          container
          style={{
            width: "80%",
            marginTop: "2rem",
            marginLeft: "10%",
            border: "2px solid var(--smoke-grey)",
            borderRadius: "15px",
            padding: "1rem",
          }}
        >
          {valueProp.map((vp, i) => {
            if (vp !== "") {
              return (
                <Grid item xs={12} md={6} padding={"0 1.5rem"} key={"gridItem" + i}>
                  <ListItem disablePadding key={"ListItem" + i}>
                    <ListItemIcon key={"ListItemIcon" + i}>
                      <CheckIcon key={"CheckIcon" + imgs} />
                    </ListItemIcon>
                    <ListItemText key={"ListItemText" + i}>
                      <span className="desktop-body" style={{ fontSize: 16 }} key={"textSpan" + i}>
                        {vp}
                      </span>
                    </ListItemText>
                  </ListItem>
                </Grid>
              );
            }
          })}
        </Grid>
      )}

      <div style={{ display: "flex", justifyContent: "center", margin: "2rem auto 2rem auto" }}>
        <button className="button-transparent">
          <span className="button-text button-text-transparent">More from {globalVendor.vendorName}</span>
        </button>
      </div>
    </>
  );
}
