import { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import CheckIcon from "@mui/icons-material/Check";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Checkbox, FormControl, FormControlLabel, Grid, Input, InputLabel, MenuItem, Modal, Select, Switch, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@aws-amplify/ui-react";

import { fromAddress } from "react-geocode";
import ImageUpload from "../ImageUpload";
import Map, { defaultMapLocation, locationType } from "../Map/Map";

import { Link, useNavigate } from "react-router-dom";
import { CreateBookableInput, CreateBookablesCategoriesInput, CreateVendorInput, SubCategory } from "../../API";
import { fetchSubCategoriesForMainCategory, mainCategories, saveBookableCategoriesToDB, saveBookableToDB, saveVendorToDB } from "../../Utils";

// @ts-ignore
import { fetchUserAttributes } from "aws-amplify/auth";
import { useGlobalContext } from "../../App";
import { Preview } from "../../pages/preview";
import { VendorTCs } from "../vendorTCs";

export interface formInput {
  friendly: string;
  field: string;
  type: string;
  optional?: boolean;
  helpText?: string;
  showIf?: string;
}

export interface formStep {
  title: string;
  subtitle: string;
  inputs: formInput[];
}

export interface formDef {
  title: string;
  instructions: string;
  formSteps: formStep[];
}

export const priceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export function VendorPriceBreakdown(price: number): ReactElement {
  return (
    <Table caption="" highlightOnHover={false}>
      <TableHead>
        <TableRow>
          <TableCell as="th" style={{ width: "80%" }}>
            Line item
          </TableCell>
          <TableCell as="th" style={{ width: "20%" }}>
            Value
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell as="th">
            <span className="desktop-body" style={{ fontSize: 18 }}>
              Townish fee (10%)
            </span>
          </TableCell>
          <TableCell as="th">
            <span className="desktop-body" style={{ fontSize: 18 }}>
              {priceFormatter.format(price * 0.1)}
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell as="th">
            <span className="desktop-body" style={{ fontSize: 18 }}>
              Paid to you (90%)
            </span>
          </TableCell>
          <TableCell as="th">
            <span className="desktop-body" style={{ fontSize: 18 }}>
              {priceFormatter.format(price * 0.9)}
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell as="th">
            <span className="desktop-body" style={{ fontSize: 18, fontWeight: "bold" }}>
              Total customer price (pre-tax)
            </span>
          </TableCell>
          <TableCell as="th">
            <span className="desktop-body" style={{ fontSize: 18, fontWeight: "bold" }}>
              {priceFormatter.format(price)}
            </span>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export function SteppedForm(props: {
  form: formDef;
  formType: string;
  objToEdit?: CreateBookableInput | CreateVendorInput;
  objCategories?: CreateBookablesCategoriesInput[];
  skipInstructions?: boolean;
}) {
  const navigate = useNavigate();

  const [formStep, setFormStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  //const [isValidationTriggered, setValidationTriggered] = useState(false);
  const [pendingFormStep, setPendingFormStep] = useState("");
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [instructionsAcknowledged, setInstructionsAcknowledged] = useState(props.skipInstructions);

  const [previewElement, setPreviewElement] = useState<ReactElement>();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  let globalSetVendor = useGlobalContext().setVendor;
  let globalVendorType = useGlobalContext().vendor?.vendorType;
  let globalVendorAddress = useGlobalContext().vendor?.vendorLocation;
  const [vendorID, setVendorID] = useState<string | undefined>(useGlobalContext().vendor?.vendorID);
  const [bookableID, setBookableID] = useState<string | undefined>();

  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [existingImagePath, setExistingImagePath] = useState<string | undefined>();
  const [mainImg, setMainImg] = useState<string | undefined>();

  const [portableService, setPortableService] = useState<boolean>(props.formType === "bookable");
  const [durationInDays, setDurationInDays] = useState<boolean>(false);
  const [inheritAddress, setInheritAddress] = useState(props.formType === "bookable");
  const [mapLocation, setMapLocation] = useState(
    props.objToEdit
      ? isBookable(props.objToEdit)
        ? JSON.parse(props.objToEdit.bookableLocation)
        : isVendor(props.objToEdit)
        ? JSON.parse(props.objToEdit.vendorLocation)
        : defaultMapLocation
      : inheritAddress && globalVendorAddress
      ? JSON.parse(globalVendorAddress)
      : defaultMapLocation
  );

  function isBookable(object: any): object is CreateBookableInput {
    return "bookbableLocation" in object;
  }

  function isVendor(object: any): object is CreateVendorInput {
    return "vendorLocation" in object;
  }

  const [pricingBreakout, setPricingBreakout] = useState();

  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);

  function convertLocationTypeToAddress(loc: locationType): { address: string; city: string; state: string; zipCode: string } {
    let cityState = loc.shortAddress.split(" ");

    return {
      address: loc.fullAddress,
      zipCode: loc.zipCode,
      city: cityState[0],
      state: cityState[1],
    };
  }

  const RegisterForm = () => {
    const {
      register,
      formState: { errors, isValidating },
      setValue,
      control,
      reset,
      resetField,
      getValues,
      handleSubmit,
      trigger,
    } = useForm();
    return { register, formState: { errors, isValidating }, setValue, reset, resetField, control, getValues, handleSubmit, trigger };
  };

  // Register multiple forms
  const forms: any = {
    vendor: RegisterForm(),
    bookable: RegisterForm(),
    // experience: registerForm(),
  };

  function checkStepErrors(): boolean {
    let errorsOnAnyInput = props.form.formSteps[formStep].inputs.some((input) => {
      return forms[props.formType].formState.errors[input.field];
    });

    return errorsOnAnyInput;
  }

  function checkStepLocationValidator(): boolean {
    let hasLocationType = props.form.formSteps[formStep].inputs.some((input) => {
      return input.type === "location";
    });

    return hasLocationType;
  }

  function handlePriceChange(e: any, inputField: string) {
    setPricingBreakout(e.target.value);
  }

  const handleChangeFormStep = (step: string) => {
    if (props.formType === "vendor" && !vendorID && forms[props.formType].getValues().vendorName) {
      let newID = forms[props.formType]
        .getValues()
        .vendorName.replace(/[^a-zA-Z0-9 ]/g, "")
        .trim()
        .replace(/\s+/g, "-")
        .toLowerCase();
      setVendorID(newID);
    }

    if (props.formType === "bookable" && !bookableID && forms[props.formType].getValues().bookableName) {
      let newID = forms[props.formType]
        .getValues()
        .bookableName.replace(/[^a-zA-Z0-9 ]/g, "")
        .trim()
        .replace(/\s+/g, "-")
        .toLowerCase();

      setBookableID(newID);
    }

    if (step === "prev" || Number(step) < formStep || (!checkStepErrors() && !forms[props.formType].formState.isValidating)) {
      setFormStep(step === "next" ? formStep + 1 : step === "prev" ? formStep - 1 : Number(step));
    }
  };

  function completeFormStep(stepToAdd: number) {
    let tempCompletedSteps = [...completedSteps];

    if (!tempCompletedSteps.includes(stepToAdd)) {
      tempCompletedSteps.push(stepToAdd);
    }

    setCompletedSteps(tempCompletedSteps);
  }

  useEffect(() => {
    if (pendingFormStep !== "" && !checkStepErrors() && !forms[props.formType].formState.isValidating) {
      completeFormStep(formStep);
      handleChangeFormStep(pendingFormStep);
      setPendingFormStep("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms[props.formType].formState.isValidating, forms[props.formType].formState.errors, pendingFormStep]);

  useEffect(() => {
    if (inheritAddress && globalVendorAddress) {
      let location = JSON.parse(globalVendorAddress);
      let { address, city, state, zipCode } = convertLocationTypeToAddress(location);

      setMapLocation(location);

      forms[props.formType].setValue("address", address);
      forms[props.formType].setValue("city", city);
      forms[props.formType].setValue("state", state);
      forms[props.formType].setValue("zipCode", zipCode);
    } else if (!inheritAddress) {
      forms[props.formType].resetField("address");
      forms[props.formType].resetField("city");
      forms[props.formType].resetField("state");
      forms[props.formType].resetField("zipCode");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inheritAddress]);

  useEffect(() => {
    if (props.formType === "vendor" && !props.objToEdit) {
      (async () => {
        let user = await fetchUserAttributes();

        if (user) {
          forms[props.formType].setValue("vendorEmail", user.email);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formType, props.objToEdit]);

  const onPreview = (data: any) => {
    if (props.formType === "vendor") {
      const vendorInfo: CreateVendorInput = {
        vendorID: vendorID!,
        vendorType: data.vendorCategory,
        vendorName: data.vendorName,
        vendorSubheading: data.vendorSubheading,
        vendorDescription: data.vendorDescription,
        vendorValueProp: data.vendorValueProp,
        vendorExperiences: null,
        vendorVideoLink: data.vendorVideoLink,
        vendorPhone: data.vendorPhone,
        vendorEmail: data.vendorEmail,
        vendorWebsiteURL: data.vendorWebsiteURL,

        vendorImgPath: existingImagePath ? existingImagePath : vendorID!,
        vendorMainImg: mainImg ? mainImg : "",
        vendorLocation: JSON.stringify({
          fullAddress: mapLocation.fullAddress,
          shortAddress: mapLocation.shortAddress,
          zipCode: data.zipCode,
          lat: mapLocation.lat,
          long: mapLocation.long,
        }),
      };

      setPreviewElement(<Preview vendor={vendorInfo} setModalOpen={setPreviewModalOpen} />);
      setPreviewModalOpen(true);
    } else if (props.formType === "bookable") {
      const bookableInfo: CreateBookableInput = {
        bookableID: bookableID!,
        bookableName: data.bookableName,
        bookableSubheading: data.bookableSubheading,
        bookableDescription: data.bookableDescription,
        bookablePrice: data.bookablePrice,
        bookablePriceType: priceType,
        bookableDuration: durationInDays ? data.bookableDuration * 24 : data.bookableDuration,
        bookableMinOccupancy: data.bookableMinOccupancy,
        bookableMaxOccupancy: data.bookableMaxOccupancy,

        bookableImgPath: existingImagePath ? existingImagePath : bookableID!,
        bookableMainImg: mainImg ? mainImg : "",
        bookableVideoLink: data.bookableVideoURL,
        bookableIsPortable: portableService,
        bookableLocation: JSON.stringify({
          fullAddress: mapLocation.fullAddress,
          shortAddress: mapLocation.shortAddress,
          zipCode: mapLocation.zipCode,
          lat: mapLocation.lat,
          long: mapLocation.long,
        }),

        vendorBookablesVendorID: vendorID,
      };
      setPreviewElement(<Preview bookable={bookableInfo} setModalOpen={setPreviewModalOpen} />);
      setPreviewModalOpen(true);
    }
  };

  const onSubmit = (data: any) => {
    if (props.formType === "vendor") {
      const vendorInfo: CreateVendorInput = {
        vendorID: vendorID!,
        vendorType: data.vendorCategory,
        vendorName: data.vendorName,
        vendorSubheading: data.vendorSubheading,
        vendorDescription: data.vendorDescription,
        vendorValueProp: data.vendorValueProp,
        vendorExperiences: null,
        vendorVideoLink: data.vendorVideoLink,
        vendorPhone: data.vendorPhone,
        vendorEmail: data.vendorEmail,
        vendorWebsiteURL: data.vendorWebsiteURL,
        vendorImgPath: existingImagePath ? existingImagePath : vendorID!,
        vendorMainImg: mainImg ? mainImg : "",
        vendorLocation: JSON.stringify({
          fullAddress: mapLocation.fullAddress,
          shortAddress: mapLocation.shortAddress,
          zipCode: data.zipCode,
          lat: mapLocation.lat,
          long: mapLocation.long,
        }),
      };

      (async () => {
        await saveVendorToDB(vendorInfo, globalSetVendor, Boolean(props.objToEdit && Object.keys(props.objToEdit).length > 0));
        navigate("/");
      })();
    } else if (props.formType === "bookable") {
      const bookableInfo: CreateBookableInput = {
        bookableID: bookableID!,
        bookableName: data.bookableName,
        bookableSubheading: data.bookableSubheading,
        bookableDescription: data.bookableDescription,
        bookablePrice: data.bookablePrice,
        bookablePriceType: priceType,
        bookableDuration: durationInDays ? data.bookableDuration * 24 : data.bookableDuration,
        bookableMinOccupancy: data.bookableMinOccupancy,
        bookableMaxOccupancy: data.bookableMaxOccupancy,

        bookableImgPath: existingImagePath ? existingImagePath : bookableID!,
        bookableMainImg: mainImg ? mainImg : "",
        bookableVideoLink: data.bookableVideoURL,
        bookableIsPortable: portableService,
        bookableLocation: JSON.stringify({
          fullAddress: mapLocation.fullAddress,
          shortAddress: mapLocation.shortAddress,
          zipCode: mapLocation.zipCode,
          lat: mapLocation.lat,
          long: mapLocation.long,
        }),

        vendorBookablesVendorID: vendorID,
      };

      const bookablesCategories: CreateBookablesCategoriesInput[] = data.bookableCategory.map((dbc: string) => {
        return {
          subCategoryId: dbc,
          bookableBookableID: bookableID,
        };
      });

      /*
      
      })](
      
      parentVendor: Vendor @belongsTo
      categories: [SubCategory] @manyToMany(relationName: "BookablesCategories")
      */
      (async () => {
        await saveBookableToDB(bookableInfo, Boolean(props.objToEdit && Object.keys(props.objToEdit).length > 0));
        await saveBookableCategoriesToDB(bookablesCategories, Boolean(props.objToEdit && Object.keys(props.objToEdit).length > 0));
        navigate("/");
      })();
    }
  };

  function validateAddress() {
    let newLocation: locationType = {
      fullAddress: forms[props.formType].getValues().address + ", " + forms[props.formType].getValues().city + ", " + forms[props.formType].getValues().state,
      shortAddress: forms[props.formType].getValues().city + " " + forms[props.formType].getValues().state,
      zipCode: forms[props.formType].getValues().zipCode,
      lat: 0,
      long: 0,
    };

    // geolocate the originString
    fromAddress(newLocation.fullAddress)
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        newLocation.lat = lat;
        newLocation.long = lng;

        setMapLocation(newLocation);
      })
      .catch(console.error);
  }

  const [bulletText, setBulletText] = useState<string>();

  const focusBulletText = (e: FocusEvent<HTMLTextAreaElement>) => {
    if (!bulletText || bulletText === "") {
      setBulletText("• ");
    }
  };
  const changeBulletText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setBulletText(e.target.value);
  };
  const checkEnter = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      setBulletText(bulletText + "• ");
    }
  };

  /*
  function categorySelected(subcatID: string): boolean {
    console.log(forms[props.formType].getValues().bookableCategory);

    if (forms[props.formType].getValues().bookableCategory) {
      return forms[props.formType].getValues().bookableCategory.some((bc: string) => {
        return bc === subcatID;
      });
    }
    return false;
  }
    */

  const [priceType, setPriceType] = useState("singlePrice");

  function handlePriceTypeChange(e: MouseEvent<HTMLElement>) {
    setPriceType((e.target as HTMLInputElement).value);
  }

  useEffect(() => {
    if (props.objToEdit && props.formType === "bookable") {
      let bookableToEdit = { ...props.objToEdit } as CreateBookableInput;

      let defaultValues: any = props.objToEdit;
      let { address, city, state, zipCode } = convertLocationTypeToAddress(JSON.parse(bookableToEdit.bookableLocation));
      let bookableCategory: string[] = [];
      if (props.objCategories) {
        bookableCategory = props.objCategories.map((cat) => {
          return cat.subCategoryId;
        });
      }

      if (bookableToEdit.bookableIsPortable !== undefined && bookableToEdit.bookableIsPortable !== null) {
        setPortableService(bookableToEdit.bookableIsPortable);
      } else {
        setPortableService(false);
      }

      setBookableID(bookableToEdit.bookableID);
      setMapLocation(JSON.parse(bookableToEdit.bookableLocation));
      setPricingBreakout(bookableToEdit.bookablePrice as any);
      setMainImg(bookableToEdit.bookableMainImg);
      setExistingImagePath(bookableToEdit.bookableImgPath);
      setPriceType(bookableToEdit.bookablePriceType);
      forms[props.formType].reset({ address, city, state, zipCode, bookableCategory, ...defaultValues });
    }

    if (props.objToEdit && props.formType === "vendor") {
      let vendorToEdit = { ...props.objToEdit } as CreateVendorInput;

      let defaultValues: any = props.objToEdit;
      let { address, city, state, zipCode } = convertLocationTypeToAddress(JSON.parse(vendorToEdit.vendorLocation));
      let vendorCategory: string = vendorToEdit.vendorType;

      setVendorID(vendorToEdit.vendorID);
      setMapLocation(JSON.parse(vendorToEdit.vendorLocation));
      setMainImg(vendorToEdit.vendorMainImg);
      setExistingImagePath(vendorToEdit.vendorImgPath);
      if (vendorToEdit.vendorValueProp) {
        setBulletText(vendorToEdit.vendorValueProp);
      }
      forms[props.formType].reset({ address, city, state, zipCode, vendorCategory, ...defaultValues });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.objToEdit]);

  useEffect(() => {
    if (globalVendorType) {
      (async () => {
        let subcats: SubCategory[] = await fetchSubCategoriesForMainCategory(globalVendorType);
        setSubCategories(subcats);
      })();
    }
  }, [globalVendorType]);

  return (
    <>
      <Modal open={previewModalOpen} onClose={() => setPreviewModalOpen(false)} style={{ width: "90%", margin: "auto", overflow: "scroll" }}>
        <div className="landing-div" style={{ height: "auto", margin: "1rem", padding: "2rem" }}>
          {previewElement}
        </div>
      </Modal>

      <Modal open={termsModalOpen} onClose={() => setTermsModalOpen(false)} style={{ width: "90%", margin: "auto", overflow: "scroll" }}>
        <div className="landing-div" style={{ height: "auto", margin: "1rem", padding: "2rem" }}>
          <VendorTCs />
        </div>
      </Modal>

      <Grid container justifyContent={"center"}>
        {formStep === 0 && !instructionsAcknowledged && (
          <Grid container direction="column" alignItems={"center"}>
            <span className="desktop-body" style={{ width: "70%", margin: "2rem 0" }}>
              {props.form.instructions}
            </span>
            <button className="button-small button-forest" onClick={() => setInstructionsAcknowledged(true)}>
              Ok
            </button>
          </Grid>
        )}
        {instructionsAcknowledged && (
          <Grid container item xs={12} lg={12} direction="column" minHeight={"70vh"} justifyContent="center" textAlign="center" padding="1rem">
            <Grid container item direction="row" marginBottom={"1rem"} justifyContent="center">
              {props.form.formSteps.map((fstep, i) => {
                return (
                  <Grid
                    container
                    item
                    direction={"row"}
                    key={"gridItem" + i}
                    xs={2.4}
                    textAlign="center"
                    justifyContent={"center"}
                    style={{ alignItems: "center", borderBottom: i === formStep ? "3px solid var(--palm-green)" : "", paddingBottom: ".5rem" }}
                  >
                    {!completedSteps.includes(i) && (
                      <div style={{ background: "lightgrey", width: "2rem", height: "2rem", borderRadius: "30px", display: "flex", marginRight: ".5rem" }}>
                        <span className="desktop-body" key={"stepSpan" + i} style={{ fontWeight: "bold", margin: "auto" }}>
                          {i + 1}
                        </span>
                      </div>
                    )}
                    {completedSteps.includes(i) && (
                      <div style={{ background: "var(--palm-green)", width: "2rem", height: "2rem", borderRadius: "30px", display: "flex", marginRight: ".5rem" }}>
                        <CheckIcon style={{ color: "white", margin: "auto", fontSize: "24px" }} />
                      </div>
                    )}
                    <span className="button-text-cream small-button-text" key={"titleSpan" + i}>
                      {fstep.title}
                    </span>
                  </Grid>
                );
              })}
            </Grid>
            <div style={{ height: "2.5rem", margin: "1rem 0 2rem 0", textAlign: "left" }}>
              <span className="desktop-body">{props.form.formSteps[formStep].subtitle}</span>
            </div>

            {props.form.formSteps[formStep].inputs.map((input) => {
              if (input.type === "location") {
                return (
                  <div key={"locationTopDiv" + input.field}>
                    {props.formType === "bookable" && (
                      <div style={{ padding: "2rem 0 0 0" }} key={"locationDivPortable" + input.field}>
                        <Checkbox key={"portableServiceCheckbox" + input.field} checked={portableService} onChange={(e) => setPortableService(!portableService)} />
                        <span className="desktop-body" style={{ marginTop: "1rem" }}>
                          My service is portable / will travel to customer site
                        </span>
                      </div>
                    )}
                    {!portableService && (
                      <div style={{ padding: "2rem 0 2rem 0" }} key={"locationDiv" + input.field}>
                        {props.formType === "bookable" && (
                          <div style={{ width: "100%" }}>
                            <Checkbox key={"inheritAddressCheckbox" + input.field} checked={inheritAddress} onChange={(e) => setInheritAddress(!inheritAddress)} />
                            <span className="desktop-body" style={{ marginTop: "1rem" }}>
                              Use address from vendor profile
                            </span>
                          </div>
                        )}

                        <button onClick={validateAddress} className="button-transparent button-small" style={{ marginBottom: "1rem" }} key={"validateAddressButton" + input.field}>
                          <span className="button-text-lime small-button-text" key={"validateAddressSpan" + input.field}>
                            Validate Address
                          </span>
                        </button>

                        <Map
                          key={"map" + input.field}
                          vendorLocations
                          mapOrigin={mapLocation}
                          markerLocations={mapLocation.fullAddress !== defaultMapLocation.fullAddress ? [mapLocation] : []}
                          distanceSliderValue={4}
                          zoomLevelOverride={7}
                          height={"350px"}
                        />
                      </div>
                    )}
                  </div>
                );
              } else if (input.type === "image") {
                return (
                  <div key={"imageParentDiv" + input.field}>
                    <div style={{ padding: "2rem" }} key={"imageDiv" + input.field}>
                      {((props.formType === "vendor" && vendorID) || (props.formType === "bookable" && bookableID)) && (
                        <>
                          <ImageUpload
                            key={"imageUpload" + input.field}
                            existingImgPath={existingImagePath}
                            vendorOrBookableID={props.formType === "vendor" ? vendorID! : bookableID!}
                            uploadedImages={uploadedImages}
                            setUploadedImages={setUploadedImages}
                            mainImg={mainImg}
                            setMainImg={setMainImg}
                          />
                          {/*<ImageGallery
                            s3Folder={props.formType === "vendor" ? vendorID! : bookableID!}
                            key={"imageGallery" + input.field}
                            imgs={imgs}
                            setImgs={setImgs}
                            uploadedImageNames={uploadedImages}
                            mainImg={mainImg}
                            setMainImg={setMainImg}
                          />*/}
                        </>
                      )}
                    </div>

                    {props.formType === "vendor" && !vendorID && (
                      <div style={{ padding: "2rem" }} key={"imageErrDiv" + input.field}>
                        <p key={"imageErrMsg" + input.field}>There was a problem creating this vendor account. Please contact support.</p>
                      </div>
                    )}
                  </div>
                );
              } else if (input.type === "checkbox") {
                return (
                  <div key={"checkboxDiv" + input.field} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Checkbox
                        required={input.optional ? false : true}
                        type={input.type}
                        defaultChecked={props.objToEdit && Object.keys(props.objToEdit).length > 0}
                        {...forms[props.formType].register(
                          input.field,
                          input.optional
                            ? {}
                            : {
                                required: input.friendly + " is required.",
                              }
                        )}
                        style={{ width: "20px", margin: "1rem" }}
                        error={forms[props.formType].formState.errors[input.field]}
                        key={"input" + input.field}
                      />
                      <span className="desktop-body">
                        I agree to the terms of the{" "}
                        <Link to="" onClick={() => setTermsModalOpen(true)}>
                          <span>Townish Vendor Agreement</span>
                        </Link>
                      </span>
                    </div>

                    {/* @ts-ignore  */}
                    <p>{forms[props.formType].formState.errors[input.field]?.message}</p>
                  </div>
                );
              } else if (input.type === "price") {
                return (
                  <div key={"priceDiv" + input.field} style={{ justifyContent: "center", marginTop: "3rem" }}>
                    <span className="desktop-body" style={{ fontSize: 18, marginRight: "1rem" }}>
                      $
                    </span>
                    <TextField
                      required={input.optional ? false : true}
                      type="number"
                      placeholder={input.friendly}
                      {...forms[props.formType].register(
                        input.field,
                        input.optional
                          ? {
                              pattern: {
                                value: /^[1-9]\d*(\d+)?$/i,
                                message: "Please enter numbers only.",
                              },
                            }
                          : {
                              required: input.friendly + " is required.",
                              pattern: {
                                value: /^[1-9]\d*(\d+)?$/i,
                                message: "Please enter numbers only.",
                              },
                            }
                      )}
                      onChange={(e) => handlePriceChange(e, input.field)}
                      error={forms[props.formType].formState.errors[input.field] ? true : false}
                      key={"input" + input.field}
                    />
                    <p>{forms[props.formType].formState.errors[input.field]?.message}</p>

                    <div style={{ textAlign: "left", width: "80%", margin: "0 auto" }}>{VendorPriceBreakdown(pricingBreakout ? pricingBreakout : 0)}</div>
                  </div>
                );
              } else if (input.type === "priceTypeBoolean") {
                return (
                  <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }} key={"priceTypeDiv" + input.field}>
                    <ToggleButtonGroup color="primary" key={"toggleButtonGrp" + input.field} value={priceType} exclusive onChange={(e) => handlePriceTypeChange(e)} aria-label="Price type">
                      <ToggleButton value="singlePrice" key={"toggleSinglePrice"}>
                        Single price
                      </ToggleButton>
                      <ToggleButton value="perPerson" key={"togglePerPerson"}>
                        Per person
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                );
              } else if (input.type === "bulletlist") {
                return (
                  <div key={"bulletParentDiv" + input.field} style={{ width: "48%", margin: "0 auto", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Input
                      required={input.optional ? false : true}
                      fullWidth
                      multiline={true}
                      rows={4}
                      placeholder={input.friendly}
                      {...forms[props.formType].register(
                        input.field,
                        input.optional
                          ? {}
                          : {
                              required: input.friendly + " is required.",
                            }
                      )}
                      error={forms[props.formType].formState.errors[input.field] ? true : false}
                      onFocus={(e: FocusEvent<HTMLTextAreaElement>) => focusBulletText(e)}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => changeBulletText(e)}
                      onKeyUp={(e: KeyboardEvent<HTMLTextAreaElement>) => checkEnter(e)}
                      value={bulletText}
                      key={"input" + input.field}
                    />
                    {input.helpText && (
                      <Tooltip title={input.helpText}>
                        <HelpOutlineIcon style={{ color: "lightblue" }} />
                      </Tooltip>
                    )}
                  </div>
                );
              } else if (input.type === "mainCategory" || input.type === "subCategory") {
                return (
                  <Grid container item key={"grid" + input.field} margin={"1rem auto"} width="50%" alignItems={"center"}>
                    <Grid container item direction="row" alignItems={"center"} xs={11}>
                      <FormControl
                        style={{ width: "100%" }}
                        required={input.optional ? false : true}
                        {...forms[props.formType].register(input.field, {
                          required: input.friendly + " is required",
                        })}
                        error={forms[props.formType].formState.errors[input.field] ? true : false}
                      >
                        <InputLabel id="categorySelect">{input.friendly}</InputLabel>
                        <Controller
                          name={input.field}
                          defaultValue={input.type === "subCategory" ? [] : ""}
                          control={forms[props.formType].control}
                          render={({ field }) => (
                            <Select
                              style={{ width: "100%" }}
                              labelId="categorySelect"
                              label={input.friendly}
                              {...field}
                              multiple={input.type === "subCategory"}
                              MenuProps={{
                                variant: "menu",
                                sx: {
                                  "&& .Mui-selected": {
                                    "&:before": {
                                      content: '"✅ \xa0"',
                                    },
                                  },
                                },
                              }}
                            >
                              {input.type === "mainCategory" &&
                                mainCategories.map((mcat) => {
                                  return (
                                    <MenuItem key={mcat.categoryName} value={mcat.id}>
                                      {mcat.categoryName}
                                    </MenuItem>
                                  );
                                })}
                              {input.type === "subCategory" &&
                                globalVendorType &&
                                subCategories.map((subcat) => {
                                  return (
                                    <MenuItem key={subcat.subCategoryName} value={subcat.id}>
                                      {subcat.subCategoryName}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      {input.helpText && (
                        <Tooltip title={input.helpText}>
                          <HelpOutlineIcon style={{ color: "lightblue" }} />
                        </Tooltip>
                      )}
                    </Grid>
                    {/* @ts-ignore  */}
                    <p style={{ justifyContent: "left" }}>{forms[props.formType].formState.errors[input.field]?.message}</p>
                  </Grid>
                );
              } else if (input.type === "hoursOrDays") {
                return (
                  <div key={"hoursOrDaysDiv" + input.field} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "50%", margin: "3rem auto 1rem auto" }}>
                    <TextField
                      required={input.optional ? false : true}
                      variant="standard"
                      type="number"
                      placeholder={input.friendly}
                      {...forms[props.formType].register(
                        input.field,
                        input.optional
                          ? {
                              pattern: {
                                value: /^[1-9]\d*(\d+)?$/i,
                                message: "Please enter numbers only.",
                              },
                            }
                          : {
                              required: input.friendly + " is required.",
                              pattern: {
                                value: /^[1-9]\d*(\d+)?$/i,
                                message: "Please enter numbers only.",
                              },
                            }
                      )}
                      error={forms[props.formType].formState.errors[input.field] ? true : false}
                      key={"input" + input.field}
                    />
                    <FormControl component="fieldset">
                      <FormControlLabel
                        value="top"
                        control={
                          <Switch
                            checked={durationInDays}
                            onChange={() => {
                              setDurationInDays(!durationInDays);
                            }}
                          />
                        }
                        label={durationInDays ? "Nights" : "Hours"}
                      />
                    </FormControl>
                  </div>
                );
              } else {
                return (
                  <Grid
                    container
                    item
                    key={"grid" + input.field}
                    margin={"1rem auto"}
                    width="50%"
                    style={{ display: input.showIf && input.showIf === "!portableService" && portableService ? "none" : "block" }}
                  >
                    <Grid container item direction="row" alignItems={"center"}>
                      <Grid item xs={11}>
                        <Input
                          required={input.optional ? false : true}
                          fullWidth
                          type={input.type}
                          multiline={input.type === "textarea"}
                          rows={input.type === "textarea" ? 4 : 1}
                          placeholder={input.friendly}
                          {...forms[props.formType].register(
                            input.field,
                            input.optional
                              ? {}
                              : {
                                  required: input.friendly + " is required.",
                                }
                          )}
                          error={forms[props.formType].formState.errors[input.field] ? true : false}
                          key={"input" + input.field}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        {input.helpText && (
                          <Tooltip title={input.helpText}>
                            <HelpOutlineIcon style={{ color: "lightblue" }} />
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                    {/* @ts-ignore  */}
                    <p>{forms[props.formType].formState.errors[input.field]?.message}</p>
                  </Grid>
                );
              }
            })}

            <Grid container item direction="row" marginTop="auto" paddingTop="auto" mt={"auto"}>
              <Grid container item xs={4} justifyContent={"start"} alignItems="end">
                {formStep > 0 && (
                  <button onClick={() => handleChangeFormStep("prev")} className="button-cream button-small">
                    <span className="button-text-cream small-button-text">Back</span>
                  </button>
                )}
              </Grid>

              <Grid container item xs={4} justifyContent={"center"} alignItems="end">
                {formStep === props.form.formSteps.length - 1 && props.formType === "bookable" && (
                  <button className="button-lime button-small" onClick={forms[props.formType].handleSubmit(onPreview)}>
                    <span className="button-text-lime small-button-text">Preview</span>
                  </button>
                )}
              </Grid>
              <Grid
                container
                item
                xs={4}
                style={{
                  justifyContent: "end",
                  alignItems: "end",
                  margin: "0 0 0 auto",
                }}
              >
                {formStep < props.form.formSteps.length - 1 && checkStepLocationValidator() && mapLocation.fullAddress === defaultMapLocation.fullAddress && (
                  <span className="button-text-lime small-button-text">Enter & validate address to continue</span>
                )}

                {formStep < props.form.formSteps.length - 1 && (!checkStepLocationValidator() || mapLocation.fullAddress !== defaultMapLocation.fullAddress) && (
                  <button
                    onClick={() => {
                      forms[props.formType].trigger(
                        props.form.formSteps[formStep].inputs.map((input) => {
                          return input.field;
                        })
                      );
                      setPendingFormStep("next");
                    }}
                    className="button-lime button-small"
                  >
                    <span className="button-text-lime small-button-text">Next</span>
                  </button>
                )}

                {formStep === props.form.formSteps.length - 1 && (
                  <button className="button-forest" onClick={forms[props.formType].handleSubmit(onSubmit)}>
                    <span className="button-text button-text-forest">
                      {props.formType === "vendor"
                        ? props.objToEdit
                          ? "Update profile"
                          : "Create account"
                        : !props.objToEdit || Object.keys(props.objToEdit).length === 0
                        ? "Create booking option"
                        : "Save changes"}
                    </span>
                  </button>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
