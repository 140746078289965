import { useEffect, useState } from "react";

import { getS3ImageNamesInFolder, getS3SingleImageAsBlob, s3ImgParams, uploadFile } from "../../Utils";

import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { FilePond, registerPlugin } from "react-filepond";

import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import { FilePondFile } from "filepond";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFilePoster, FilePondPluginFileValidateType);

export default function ImageUpload(props: {
  vendorOrBookableID: string;
  existingImgPath?: string;
  uploadedImages: string[];
  setUploadedImages: React.Dispatch<React.SetStateAction<string[]>>;
  mainImg: string | undefined;
  setMainImg: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const [files, setFiles] = useState<any>([]);

  let imgPath = props.existingImgPath ? props.existingImgPath : props.vendorOrBookableID;

  function highlightMainImg(filename: string) {
    if (filename !== "") {
      const getEl: NodeListOf<HTMLElement> = document.querySelectorAll(".filepond--file-info-main");
      for (let i = 0; i < getEl.length; i++) {
        if (getEl[i].innerText === filename) {
          //getEl[i].innerText += " - Main image";
          getEl[i].style.backgroundColor = "var(--lime-green-transparent)";
        } else {
          //getEl[i].innerText = getEl[i].innerText.split(" -")[0];
          getEl[i].removeAttribute("style");
        }
      }
    }
  }

  function selectMainImg(file: FilePondFile) {
    props.setMainImg(file.filename);
    highlightMainImg(file.filename);
  }

  // set the first image to the main image if there isn't another selection
  useEffect(() => {
    if (files.length > 0 && (!props.mainImg || props.mainImg === "")) {
      selectMainImg(files[0]);
    } else if (props.mainImg) {
      highlightMainImg(props.mainImg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    (async () => {
      const fetchedImageNames = await getS3ImageNamesInFolder(imgPath + "/");

      setFiles(
        fetchedImageNames.map((fin) => {
          return {
            source: fin,
            options: {
              type: "local",
              metadata: {
                poster: s3ImgParams.s3ImgPath + encodeURIComponent(fin),
              },
            },
          };
        })
      );
    })();
  }, [props.vendorOrBookableID]);

  return (
    <div style={{ margin: "2rem 0", display: "flex", flexDirection: "column" }}>
      <FilePond
        files={files}
        onupdatefiles={setFiles}
        acceptedFileTypes={["image/*"]}
        onactivatefile={(file) => selectMainImg(file)}
        onprocessfiles={() => highlightMainImg(props.mainImg ? props.mainImg : "")}
        allowMultiple={true}
        maxFiles={8}
        server={{
          process: function (fieldName: any, file: any, metadata: any, load: any, error: any, progress: any, abort: any) {
            uploadFile({ imgPath: imgPath, file: file });

            load(file);
          },
          load: async function (source, load, error, progress, abort, headers) {
            let file = await getS3SingleImageAsBlob(source);

            load(file);
          },
          /* remove works on the FilePond side but fails due to S3/IAM policy - need to be able to enable an object owner to delete via S3
          remove: async function (source, load, error) {
            await deleteS3Image(source);
          },*/
        }}
        instantUpload={true}
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
    </div>
  );
}
