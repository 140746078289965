import { Grid } from "@mui/material";

import { Bookable } from "../../API";

import { s3ImgParams } from "../../Utils";
import { locationType } from "../Map/Map";

const placeholderImage = require("../../img/svc_placeholder_1.png");

export default function BookableCard(props: { bk: Bookable }) {
  const bookableLocation: locationType = JSON.parse(props.bk.bookableLocation);

  return (
    <div
      className="basic-div"
      style={{
        minHeight: "400px",
        borderRadius: "8px",
      }}
      key={"div" + props.bk.bookableID}
    >
      <Grid container direction="column" key={"Grid-cont" + props.bk.bookableID}>
        <Grid item key={"Grid-item" + props.bk.bookableID}>
          <div
            style={{
              backgroundColor: "var(--smoke-grey)",
              width: "100%",
              borderRadius: "8px",
              display: "block",
            }}
          >
            <img
              src={props.bk.bookableMainImg ? s3ImgParams.s3ImgPath + props.bk.bookableImgPath + "/" + encodeURIComponent(props.bk.bookableMainImg) : placeholderImage}
              alt={props.bk.bookableName + " thumbnail"}
              style={{
                width: "100%",
                aspectRatio: 1,
                objectFit: "cover",
                borderRadius: "8px",
                display: "block",
              }}
              key={"Img" + props.bk.bookableID}
            />
          </div>
        </Grid>
        <Grid container item key={"Grid-cont2" + props.bk.bookableID}>
          <h5
            style={{
              margin: ".1rem 0 0 .5rem",
              color: "var(--forest-green)",
              overflow: "hidden",
              textAlign: "left",
            }}
            key={"name" + props.bk.bookableID}
          >
            {props.bk.bookableName}
          </h5>
        </Grid>
        <Grid container item key={"Grid-subheading" + props.bk.bookableID}>
          <span
            className="desktop-body"
            style={{
              fontSize: "18px",
              margin: "0 0 0 .5rem",
              color: "grey",
              overflow: "hidden",
              textAlign: "left",
            }}
            key={"subheading" + props.bk.bookableID}
          >
            {props.bk.bookableSubheading}
          </span>
        </Grid>

        <Grid container direction="row">
          <Grid container item xs={6}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                margin: "0 0 0 .5rem",
              }}
              key={"Grid-item2" + props.bk.bookableID}
            >
              <span
                className="desktop-body2"
                style={{
                  fontSize: 16,
                  textAlign: "left",
                  maxHeight: "1.5rem",
                  overflow: "hidden",
                }}
                key={"address" + props.bk.bookableID}
              >
                {bookableLocation.shortAddress}
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                margin: "-.5rem 0 0 .5rem",
              }}
              key={"Grid-item3" + props.bk.bookableID}
            >
              <span className="desktop-body2" style={{ fontSize: 16 }} key={"groupSize" + props.bk.bookableID}>
                Groups up to {props.bk.bookableMaxOccupancy}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={6} justifyContent={"right"} textAlign={"right"} key={"Grid-item-price" + props.bk.bookableID}>
            {props.bk.bookablePrice && (
              <span className="desktop-body2" style={{ fontSize: 16 }} key={"priceFrom" + props.bk.bookableID}>
                from ${props.bk.bookablePrice.toLocaleString()}
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
