import { Authenticator, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { getCurrentUser, signOut } from "aws-amplify/auth";

/*
export function RequireAuth({ children }: any) {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== "authenticated") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

async function currentAuthenticatedUser() {
  try {
    await getCurrentUser();
    //console.log(`The username: ${username}`);
    //console.log(`The userId: ${userId}`);
    //console.log(`The signInDetails: ${signInDetails}`);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function checkUserSignedIn() {
  try {
    const { /*username,*/ /* userId } = await getCurrentUser();

    return Boolean(userId);
  } catch (err) {
    return false;
  }
}
*/
export async function getAWSUserInfo() {
  try {
    const user = await getCurrentUser();

    return user;
  } catch (err) {
    return false;
  }
}

export async function handleSignOut() {
  try {
    await signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

export function Login() {
  return (
    <div>
      <h5>Login</h5>
      <View className="auth-wrapper" style={{ margin: "auto auto auto auto" }}>
        <div
          style={{
            maxWidth: "50%",
            maxHeight: "66%",
            overflow: "auto",
            margin: "5% auto auto auto",
            backgroundColor: "white",
            border: "2px solid",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            padding: "2rem",
          }}
        >
          <h5 style={{ margin: "0 auto 2rem auto", maxWidth: "80%" }}>
            <p style={{ marginBottom: "1rem" }}>Townish has dozens of pre-built, customizable experiences that are perfect for your team!</p>
            <p style={{ marginBottom: 0 }}>Sign in to your account to get started</p>
          </h5>
          <Authenticator />
        </div>
      </View>
    </div>
  );
}
