/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createMainCategory = /* GraphQL */ `mutation CreateMainCategory(
  $input: CreateMainCategoryInput!
  $condition: ModelMainCategoryConditionInput
) {
  createMainCategory(input: $input, condition: $condition) {
    id
    categoryName
    categoryDescription
    categoryImg
    categoryIcon
    categorySequence
    showAsVendors
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMainCategoryMutationVariables,
  APITypes.CreateMainCategoryMutation
>;
export const updateMainCategory = /* GraphQL */ `mutation UpdateMainCategory(
  $input: UpdateMainCategoryInput!
  $condition: ModelMainCategoryConditionInput
) {
  updateMainCategory(input: $input, condition: $condition) {
    id
    categoryName
    categoryDescription
    categoryImg
    categoryIcon
    categorySequence
    showAsVendors
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMainCategoryMutationVariables,
  APITypes.UpdateMainCategoryMutation
>;
export const deleteMainCategory = /* GraphQL */ `mutation DeleteMainCategory(
  $input: DeleteMainCategoryInput!
  $condition: ModelMainCategoryConditionInput
) {
  deleteMainCategory(input: $input, condition: $condition) {
    id
    categoryName
    categoryDescription
    categoryImg
    categoryIcon
    categorySequence
    showAsVendors
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMainCategoryMutationVariables,
  APITypes.DeleteMainCategoryMutation
>;
export const createSubCategory = /* GraphQL */ `mutation CreateSubCategory(
  $input: CreateSubCategoryInput!
  $condition: ModelSubCategoryConditionInput
) {
  createSubCategory(input: $input, condition: $condition) {
    id
    parentCategoryID
    subCategoryName
    subCategoryImg
    showAsVendors
    bookables {
      items {
        id
        subCategoryId
        bookableBookableID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSubCategoryMutationVariables,
  APITypes.CreateSubCategoryMutation
>;
export const updateSubCategory = /* GraphQL */ `mutation UpdateSubCategory(
  $input: UpdateSubCategoryInput!
  $condition: ModelSubCategoryConditionInput
) {
  updateSubCategory(input: $input, condition: $condition) {
    id
    parentCategoryID
    subCategoryName
    subCategoryImg
    showAsVendors
    bookables {
      items {
        id
        subCategoryId
        bookableBookableID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSubCategoryMutationVariables,
  APITypes.UpdateSubCategoryMutation
>;
export const deleteSubCategory = /* GraphQL */ `mutation DeleteSubCategory(
  $input: DeleteSubCategoryInput!
  $condition: ModelSubCategoryConditionInput
) {
  deleteSubCategory(input: $input, condition: $condition) {
    id
    parentCategoryID
    subCategoryName
    subCategoryImg
    showAsVendors
    bookables {
      items {
        id
        subCategoryId
        bookableBookableID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSubCategoryMutationVariables,
  APITypes.DeleteSubCategoryMutation
>;
export const createVendor = /* GraphQL */ `mutation CreateVendor(
  $input: CreateVendorInput!
  $condition: ModelVendorConditionInput
) {
  createVendor(input: $input, condition: $condition) {
    vendorID
    vendorType
    vendorName
    vendorSubheading
    vendorDescription
    vendorValueProp
    vendorImgPath
    vendorMainImg
    vendorVideoLink
    vendorPhone
    vendorEmail
    vendorWebsiteURL
    bookables {
      items {
        bookableID
        bookableName
        bookableSubheading
        bookableDescription
        bookableIsPortable
        bookableLocation
        bookableImgPath
        bookableMainImg
        bookableVideoLink
        bookablePrice
        bookablePriceType
        bookableDuration
        bookableMinOccupancy
        bookableMaxOccupancy
        createdAt
        updatedAt
        vendorBookablesVendorID
        owner
        __typename
      }
      nextToken
      __typename
    }
    vendorLocation
    vendorExperiences
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVendorMutationVariables,
  APITypes.CreateVendorMutation
>;
export const updateVendor = /* GraphQL */ `mutation UpdateVendor(
  $input: UpdateVendorInput!
  $condition: ModelVendorConditionInput
) {
  updateVendor(input: $input, condition: $condition) {
    vendorID
    vendorType
    vendorName
    vendorSubheading
    vendorDescription
    vendorValueProp
    vendorImgPath
    vendorMainImg
    vendorVideoLink
    vendorPhone
    vendorEmail
    vendorWebsiteURL
    bookables {
      items {
        bookableID
        bookableName
        bookableSubheading
        bookableDescription
        bookableIsPortable
        bookableLocation
        bookableImgPath
        bookableMainImg
        bookableVideoLink
        bookablePrice
        bookablePriceType
        bookableDuration
        bookableMinOccupancy
        bookableMaxOccupancy
        createdAt
        updatedAt
        vendorBookablesVendorID
        owner
        __typename
      }
      nextToken
      __typename
    }
    vendorLocation
    vendorExperiences
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVendorMutationVariables,
  APITypes.UpdateVendorMutation
>;
export const deleteVendor = /* GraphQL */ `mutation DeleteVendor(
  $input: DeleteVendorInput!
  $condition: ModelVendorConditionInput
) {
  deleteVendor(input: $input, condition: $condition) {
    vendorID
    vendorType
    vendorName
    vendorSubheading
    vendorDescription
    vendorValueProp
    vendorImgPath
    vendorMainImg
    vendorVideoLink
    vendorPhone
    vendorEmail
    vendorWebsiteURL
    bookables {
      items {
        bookableID
        bookableName
        bookableSubheading
        bookableDescription
        bookableIsPortable
        bookableLocation
        bookableImgPath
        bookableMainImg
        bookableVideoLink
        bookablePrice
        bookablePriceType
        bookableDuration
        bookableMinOccupancy
        bookableMaxOccupancy
        createdAt
        updatedAt
        vendorBookablesVendorID
        owner
        __typename
      }
      nextToken
      __typename
    }
    vendorLocation
    vendorExperiences
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVendorMutationVariables,
  APITypes.DeleteVendorMutation
>;
export const createBookable = /* GraphQL */ `mutation CreateBookable(
  $input: CreateBookableInput!
  $condition: ModelBookableConditionInput
) {
  createBookable(input: $input, condition: $condition) {
    bookableID
    parentVendor {
      vendorID
      vendorType
      vendorName
      vendorSubheading
      vendorDescription
      vendorValueProp
      vendorImgPath
      vendorMainImg
      vendorVideoLink
      vendorPhone
      vendorEmail
      vendorWebsiteURL
      bookables {
        nextToken
        __typename
      }
      vendorLocation
      vendorExperiences
      owner
      createdAt
      updatedAt
      __typename
    }
    bookableName
    bookableSubheading
    bookableDescription
    bookableIsPortable
    bookableLocation
    bookableImgPath
    bookableMainImg
    bookableVideoLink
    bookablePrice
    bookablePriceType
    bookableDuration
    bookableMinOccupancy
    bookableMaxOccupancy
    categories {
      items {
        id
        subCategoryId
        bookableBookableID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    vendorBookablesVendorID
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBookableMutationVariables,
  APITypes.CreateBookableMutation
>;
export const updateBookable = /* GraphQL */ `mutation UpdateBookable(
  $input: UpdateBookableInput!
  $condition: ModelBookableConditionInput
) {
  updateBookable(input: $input, condition: $condition) {
    bookableID
    parentVendor {
      vendorID
      vendorType
      vendorName
      vendorSubheading
      vendorDescription
      vendorValueProp
      vendorImgPath
      vendorMainImg
      vendorVideoLink
      vendorPhone
      vendorEmail
      vendorWebsiteURL
      bookables {
        nextToken
        __typename
      }
      vendorLocation
      vendorExperiences
      owner
      createdAt
      updatedAt
      __typename
    }
    bookableName
    bookableSubheading
    bookableDescription
    bookableIsPortable
    bookableLocation
    bookableImgPath
    bookableMainImg
    bookableVideoLink
    bookablePrice
    bookablePriceType
    bookableDuration
    bookableMinOccupancy
    bookableMaxOccupancy
    categories {
      items {
        id
        subCategoryId
        bookableBookableID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    vendorBookablesVendorID
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBookableMutationVariables,
  APITypes.UpdateBookableMutation
>;
export const deleteBookable = /* GraphQL */ `mutation DeleteBookable(
  $input: DeleteBookableInput!
  $condition: ModelBookableConditionInput
) {
  deleteBookable(input: $input, condition: $condition) {
    bookableID
    parentVendor {
      vendorID
      vendorType
      vendorName
      vendorSubheading
      vendorDescription
      vendorValueProp
      vendorImgPath
      vendorMainImg
      vendorVideoLink
      vendorPhone
      vendorEmail
      vendorWebsiteURL
      bookables {
        nextToken
        __typename
      }
      vendorLocation
      vendorExperiences
      owner
      createdAt
      updatedAt
      __typename
    }
    bookableName
    bookableSubheading
    bookableDescription
    bookableIsPortable
    bookableLocation
    bookableImgPath
    bookableMainImg
    bookableVideoLink
    bookablePrice
    bookablePriceType
    bookableDuration
    bookableMinOccupancy
    bookableMaxOccupancy
    categories {
      items {
        id
        subCategoryId
        bookableBookableID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    vendorBookablesVendorID
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBookableMutationVariables,
  APITypes.DeleteBookableMutation
>;
export const createBookingRequest = /* GraphQL */ `mutation CreateBookingRequest(
  $input: CreateBookingRequestInput!
  $condition: ModelBookingRequestConditionInput
) {
  createBookingRequest(input: $input, condition: $condition) {
    id
    customerAndVendorIDs
    bookingBookableID
    bookingVendorID
    bookingRequestorUserID
    bookingRequestorName
    bookingRequestorEmail
    bookingNumAttendees
    bookingType
    bookingStartDateTime
    bookingDuration
    bookingDurationUnit
    bookingStatus
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBookingRequestMutationVariables,
  APITypes.CreateBookingRequestMutation
>;
export const updateBookingRequest = /* GraphQL */ `mutation UpdateBookingRequest(
  $input: UpdateBookingRequestInput!
  $condition: ModelBookingRequestConditionInput
) {
  updateBookingRequest(input: $input, condition: $condition) {
    id
    customerAndVendorIDs
    bookingBookableID
    bookingVendorID
    bookingRequestorUserID
    bookingRequestorName
    bookingRequestorEmail
    bookingNumAttendees
    bookingType
    bookingStartDateTime
    bookingDuration
    bookingDurationUnit
    bookingStatus
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBookingRequestMutationVariables,
  APITypes.UpdateBookingRequestMutation
>;
export const deleteBookingRequest = /* GraphQL */ `mutation DeleteBookingRequest(
  $input: DeleteBookingRequestInput!
  $condition: ModelBookingRequestConditionInput
) {
  deleteBookingRequest(input: $input, condition: $condition) {
    id
    customerAndVendorIDs
    bookingBookableID
    bookingVendorID
    bookingRequestorUserID
    bookingRequestorName
    bookingRequestorEmail
    bookingNumAttendees
    bookingType
    bookingStartDateTime
    bookingDuration
    bookingDurationUnit
    bookingStatus
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBookingRequestMutationVariables,
  APITypes.DeleteBookingRequestMutation
>;
export const createMessage = /* GraphQL */ `mutation CreateMessage(
  $input: CreateMessageInput!
  $condition: ModelMessageConditionInput
) {
  createMessage(input: $input, condition: $condition) {
    id
    senderAndRecipientCognitoIDs
    senderCognitoID
    senderName
    senderPhone
    senderEmail
    recipientInternalID
    recipientType
    messageSubject
    messageBody
    messageOriginalMessageID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageMutationVariables,
  APITypes.CreateMessageMutation
>;
export const updateMessage = /* GraphQL */ `mutation UpdateMessage(
  $input: UpdateMessageInput!
  $condition: ModelMessageConditionInput
) {
  updateMessage(input: $input, condition: $condition) {
    id
    senderAndRecipientCognitoIDs
    senderCognitoID
    senderName
    senderPhone
    senderEmail
    recipientInternalID
    recipientType
    messageSubject
    messageBody
    messageOriginalMessageID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageMutationVariables,
  APITypes.UpdateMessageMutation
>;
export const deleteMessage = /* GraphQL */ `mutation DeleteMessage(
  $input: DeleteMessageInput!
  $condition: ModelMessageConditionInput
) {
  deleteMessage(input: $input, condition: $condition) {
    id
    senderAndRecipientCognitoIDs
    senderCognitoID
    senderName
    senderPhone
    senderEmail
    recipientInternalID
    recipientType
    messageSubject
    messageBody
    messageOriginalMessageID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageMutationVariables,
  APITypes.DeleteMessageMutation
>;
export const createBookablesCategories = /* GraphQL */ `mutation CreateBookablesCategories(
  $input: CreateBookablesCategoriesInput!
  $condition: ModelBookablesCategoriesConditionInput
) {
  createBookablesCategories(input: $input, condition: $condition) {
    id
    subCategoryId
    bookableBookableID
    subCategory {
      id
      parentCategoryID
      subCategoryName
      subCategoryImg
      showAsVendors
      bookables {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    bookable {
      bookableID
      parentVendor {
        vendorID
        vendorType
        vendorName
        vendorSubheading
        vendorDescription
        vendorValueProp
        vendorImgPath
        vendorMainImg
        vendorVideoLink
        vendorPhone
        vendorEmail
        vendorWebsiteURL
        vendorLocation
        vendorExperiences
        owner
        createdAt
        updatedAt
        __typename
      }
      bookableName
      bookableSubheading
      bookableDescription
      bookableIsPortable
      bookableLocation
      bookableImgPath
      bookableMainImg
      bookableVideoLink
      bookablePrice
      bookablePriceType
      bookableDuration
      bookableMinOccupancy
      bookableMaxOccupancy
      categories {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vendorBookablesVendorID
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBookablesCategoriesMutationVariables,
  APITypes.CreateBookablesCategoriesMutation
>;
export const updateBookablesCategories = /* GraphQL */ `mutation UpdateBookablesCategories(
  $input: UpdateBookablesCategoriesInput!
  $condition: ModelBookablesCategoriesConditionInput
) {
  updateBookablesCategories(input: $input, condition: $condition) {
    id
    subCategoryId
    bookableBookableID
    subCategory {
      id
      parentCategoryID
      subCategoryName
      subCategoryImg
      showAsVendors
      bookables {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    bookable {
      bookableID
      parentVendor {
        vendorID
        vendorType
        vendorName
        vendorSubheading
        vendorDescription
        vendorValueProp
        vendorImgPath
        vendorMainImg
        vendorVideoLink
        vendorPhone
        vendorEmail
        vendorWebsiteURL
        vendorLocation
        vendorExperiences
        owner
        createdAt
        updatedAt
        __typename
      }
      bookableName
      bookableSubheading
      bookableDescription
      bookableIsPortable
      bookableLocation
      bookableImgPath
      bookableMainImg
      bookableVideoLink
      bookablePrice
      bookablePriceType
      bookableDuration
      bookableMinOccupancy
      bookableMaxOccupancy
      categories {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vendorBookablesVendorID
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBookablesCategoriesMutationVariables,
  APITypes.UpdateBookablesCategoriesMutation
>;
export const deleteBookablesCategories = /* GraphQL */ `mutation DeleteBookablesCategories(
  $input: DeleteBookablesCategoriesInput!
  $condition: ModelBookablesCategoriesConditionInput
) {
  deleteBookablesCategories(input: $input, condition: $condition) {
    id
    subCategoryId
    bookableBookableID
    subCategory {
      id
      parentCategoryID
      subCategoryName
      subCategoryImg
      showAsVendors
      bookables {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    bookable {
      bookableID
      parentVendor {
        vendorID
        vendorType
        vendorName
        vendorSubheading
        vendorDescription
        vendorValueProp
        vendorImgPath
        vendorMainImg
        vendorVideoLink
        vendorPhone
        vendorEmail
        vendorWebsiteURL
        vendorLocation
        vendorExperiences
        owner
        createdAt
        updatedAt
        __typename
      }
      bookableName
      bookableSubheading
      bookableDescription
      bookableIsPortable
      bookableLocation
      bookableImgPath
      bookableMainImg
      bookableVideoLink
      bookablePrice
      bookablePriceType
      bookableDuration
      bookableMinOccupancy
      bookableMaxOccupancy
      categories {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vendorBookablesVendorID
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBookablesCategoriesMutationVariables,
  APITypes.DeleteBookablesCategoriesMutation
>;
