import { Grid } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../img/townish_logo_forest.svg";

import { useGlobalContext } from "../../App";
import { handleSignOut } from "../../pages/login";

export function Nav(props: { title: string }) {
  let vendor = useGlobalContext().vendor;
  let resetGlobalVendor = useGlobalContext().setVendor;

  return (
    <>
      <Grid templateColumns="1fr 4fr 1fr 1fr" justifyContent={"left"} style={{ padding: "1.5rem" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/"} style={{ height: "43px" }}>
            <Logo style={{ filter: "brightness(0%)" }} />
          </Link>
        </div>
        <div style={{ display: "flex", alignItems: "center", marginLeft: "3rem" }}>
          <h5 style={{ margin: 0 }}>{props.title}</h5>
        </div>
        {vendor && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Link to={"/pages/editVendor"}>
              <button className="button-transparent button-small">Vendor Profile</button>
            </Link>
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="button-transparent button-small"
            onClick={() => {
              handleSignOut();
              resetGlobalVendor(undefined);
            }}
          >
            Sign Out
          </button>
        </div>
      </Grid>
    </>
  );
}
