import { Button, Grid, Icon } from "@mui/material";
import { Fragment } from "react";
import Carousel from "react-multi-carousel";
import { Bookable, CreateBookableInput, CreateVendorInput } from "../../API";
import { s3ImgParams } from "../../Utils";
import BookableCard from "../../components/bookableCard";

export const responsiveCarousel = {
  desktop: {
    breakpoint: { max: 3000, min: 1500 },
    items: 4,
  },
  medium: {
    breakpoint: { max: 1499, min: 800 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 799, min: 1 },
    items: 1,
  },
};

export default function VendorDetailElement(props: {
  bookableView?: boolean;
  vendor: CreateVendorInput;
  bookables: Bookable[];
  //amenities: Amenity[];
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  // const vendorExperiences = props.vendor.vendorExperiences ? JSON.parse(props.vendor.vendorExperiences) : [];
  /*
  function VendorDetailAmenities(props: { amenities: Amenity[] }) {
    return (
      <>
        <p className="h6b" style={{ color: "var(--forest-green)", margin: "1rem 0 1rem  1rem", textAlign: "left" }}>
          includes
        </p>
        <Grid container item xs={12} sx={{ mx: "auto" }}>
          {props.amenities.map((amn: any) => {
            return (
              <Fragment key={"frag" + amn.id}>
                <Grid container item xs={6} lg={3} mb={3} key={"Grid" + amn.id} direction="row" alignItems={"top"}>
                  <Grid item xs={3}>
                    <Icon style={{ fontSize: 42, color: "var(--palm-green)" }} key={"Icon" + amn.id}>
                      {amn.amenityIcon}
                    </Icon>
                  </Grid>
                  <Grid item xs={9} textAlign={"left"}>
                    <span className="desktop-body">{amn.amenityName}</span>
                  </Grid>
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </>
    );
  }
*/
  return (
    <>
      <Grid container item direction="row" style={{ margin: "1rem auto 0 auto" }}>
        <Grid container item xs={8} direction="column" paddingRight="1.5rem" textAlign={"left"}>
          <h5 style={{ margin: "1rem 0" }}>{props.bookableView ? "About " + props.vendor.vendorName : props.vendor.vendorName}</h5>
          <div style={{ textAlign: "left" }}>
            {props.vendor.vendorSubheading && (
              <h5
                style={{
                  color: "var(--forest-green)",
                  margin: "0 0 .5rem 0",
                  fontSize: "22px",
                }}
              >
                {props.vendor.vendorSubheading}
              </h5>
            )}
            <Button variant="contained" color={"info"} sx={{ width: "40%" }}>
              Contact {props.vendor.vendorName}
            </Button>
          </div>
          <div style={{ textAlign: "left", margin: "0 auto 2rem auto" }}>
            <span className="desktop-body">{props.vendor.vendorDescription}</span>
          </div>
        </Grid>
        <Grid container item xs={4}>
          <img
            src={s3ImgParams.s3ImgPath + props.vendor.vendorImgPath + encodeURIComponent(props.vendor.vendorMainImg)}
            alt="vendor"
            style={{
              borderRadius: "20px",
              width: "100%",
              maxHeight: "500px",
              objectFit: "cover",
            }}
          />
        </Grid>
      </Grid>

      {/* props.amenities && (
        <div
          style={{
            marginTop: "2rem",
            marginLeft: "10%",
            border: "2px solid var(--smoke-grey)",
            borderRadius: "15px",
          }}
        >
          <VendorDetailAmenities amenities={props.amenities} />
        </div>
      ) */}

      {props.bookables && props.bookables.length > 0 && (
        <div
          style={{
            marginTop: "2rem",
            marginLeft: "10%",
            textAlign: "left",
          }}
        >
          <h5 style={{ margin: "2rem 0 0 0" }}>Engage {props.vendor.vendorName}:</h5>
          <Carousel
            renderButtonGroupOutside={true}
            arrows={true}
            responsive={responsiveCarousel}
            autoPlay={true}
            autoPlaySpeed={6000}
            swipeable={false}
            draggable={false}
            showDots={false}
            infinite={true}
            partialVisible={true}
            dotListClass="custom-dot-list-style"
          >
            {props.bookables &&
              props.bookables.map((bkbl: Bookable | null, i: number) => {
                if (bkbl) {
                  return (
                    <div className="slider" key={i} style={{ margin: "0 .5rem" }}>
                      <BookableCard bk={bkbl} />
                    </div>
                  );
                } else {
                  return <></>;
                }
              })}
          </Carousel>
        </div>
      )}

      {/* vendorExperiences && vendorExperiences?.length > 0 && (
        <div
          style={{
          
            marginTop: "2rem",
            marginLeft: "10%",
            textAlign: "left",
          }}
        >
          <h5 style={{ margin: "2rem 0 0 0" }}>Full experiences with {props.vendor.vendorName}:</h5>
          <Carousel
            renderButtonGroupOutside={true}
            arrows={true}
            responsive={responsiveCarousel}
            autoPlay={true}
            autoPlaySpeed={6000}
            swipeable={false}
            draggable={false}
            showDots={false}
            infinite={true}
            partialVisible={true}
            dotListClass="custom-dot-list-style"
          >
            {vendorExperiences.map((exp: string, i: number) => {
              let foundExperience = findExperienceByID(exp);

              if (foundExperience) {
                return (
                  <div className="slider" key={i} style={{ margin: "0 .5rem" }}>
                    <ExperienceCard experience={foundExperience} />
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </Carousel>
        </div>
      ) */}
    </>
  );
}
