import { ReactElement } from "react";

import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

import { DeleteObjectCommand, GetObjectCommand, GetObjectCommandOutput, ListObjectsV2Command, PutObjectCommand, S3Client, S3ClientConfigType } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

import { generateClient } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { Bookable, BookablesCategories, bookingRequest, CreateBookableInput, CreateBookablesCategoriesInput, CreateVendorInput, MainCategory, SubCategory } from "./API";
import { createBookable, createBookablesCategories, createVendor, deleteBookablesCategories, updateBookable, updateVendor } from "./graphql/mutations";
import { bookablesCategoriesByBookableBookableID, getBookable, listBookables, listBookablesCategories, listSubCategories } from "./graphql/queries";
const amplifyClient = generateClient({ authMode: "userPool" });

export const mainCategories: MainCategory[] = [
  {
    __typename: "MainCategory",
    id: "speakers-and-workshops",
    categoryName: "Speakers & workshops",
    categoryDescription: "",
    categoryImg: "",
    categoryIcon: "",
    categorySequence: 0,
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "MainCategory",
    id: "team-building-activities",
    categoryName: "Team building activities",
    categoryDescription: "",
    categoryImg: "",
    categoryIcon: "",
    categorySequence: 1,
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "MainCategory",
    id: "venues",
    categoryName: "Venues",
    categoryDescription: "",
    categoryImg: "",
    categoryIcon: "",
    categorySequence: 2,
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "MainCategory",
    id: "accommodation",
    categoryName: "Accommodation",
    categoryDescription: "",
    categoryImg: "",
    categoryIcon: "",
    categorySequence: 3,
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
];

/*

export const subCategories: SubCategory[] = [
  {
    __typename: "SubCategory",
    id: "professional-development",
    parentCategoryID: "speakers-and-workshops",
    subCategoryName: "Professional development",
    subCategoryImg: "",
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "SubCategory",
    id: "thought-leadership",
    parentCategoryID: "speakers-and-workshops",
    subCategoryName: "Thought Leadership",
    subCategoryImg: "",
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "SubCategory",
    id: "mind-and-body",
    parentCategoryID: "speakers-and-workshops",
    subCategoryName: "Mind & Body",
    subCategoryImg: "",
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "SubCategory",
    id: "sports-and-games",
    parentCategoryID: "team-building-activities",
    subCategoryName: "Sports & games",
    subCategoryImg: "",
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "SubCategory",
    id: "tours-and-out-of-town",
    parentCategoryID: "team-building-activities",
    subCategoryName: "Tours & out of town",
    subCategoryImg: "",
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "SubCategory",
    id: "cooking-and-drinking",
    parentCategoryID: "team-building-activities",
    subCategoryName: "Cooking & drinking",
    subCategoryImg: "",
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "SubCategory",
    id: "art-and-music",
    parentCategoryID: "team-building-activities",
    subCategoryName: "Art & music",
    subCategoryImg: "",
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "SubCategory",
    id: "creative-spaces",
    parentCategoryID: "venues",
    subCategoryName: "Creative spaces",
    subCategoryImg: "",
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "SubCategory",
    id: "meeting-rooms",
    parentCategoryID: "venues",
    subCategoryName: "Meeting rooms",
    subCategoryImg: "",
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "SubCategory",
    id: "event-spaces",
    parentCategoryID: "venues",
    subCategoryName: "Event spaces",
    subCategoryImg: "",
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "SubCategory",
    id: "nature",
    parentCategoryID: "accommodation",
    subCategoryName: "Nature",
    subCategoryImg: "",
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "SubCategory",
    id: "urban",
    parentCategoryID: "accommodation",
    subCategoryName: "Urban",
    subCategoryImg: "",
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "SubCategory",
    id: "rustic",
    parentCategoryID: "accommodation",
    subCategoryName: "Rustic",
    subCategoryImg: "",
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
  {
    __typename: "SubCategory",
    id: "luxury",
    parentCategoryID: "accommodation",
    subCategoryName: "Luxury",
    subCategoryImg: "",
    createdAt: "2024-02-20T17:17:41.020Z",
    updatedAt: "2024-02-20T17:17:41.020Z",
  },
];



function getSubCategoriesInMainCategory(mainCatId: string): SubCategory[] {
  return subCategories.filter((subcat) => subcat.parentCategoryID === mainCatId);
}

*/

// sanitize strings that may or may not contain the S3 folder, e.g. S3keys
export function getImageNameOnly(img: string) {
  let imgName = img.split("/").pop();

  if (!imgName) {
    console.log("invalid folder value in getImagesForService");
    return "";
  }

  return imgName;
}

export async function fetchSubCategoriesForMainCategory(mainCatId: string): Promise<SubCategory[]> {
  let listResponse = await amplifyClient.graphql({
    query: listSubCategories,
    variables: {
      filter: { parentCategoryID: { eq: mainCatId } },
    },
  });

  return listResponse.data.listSubCategories.items as SubCategory[];
}

export const s3ImgParams = {
  s3ImgPath: "https://townish-serviceimages-dev.s3.ca-central-1.amazonaws.com/",
  Bucket: "townish-serviceimages-dev",
  Prefix: "",
  Delimiter: "/",
};

const s3client = new S3Client({
  region: "ca-central-1",
  credentials: fromCognitoIdentityPool({
    clientConfig: { region: "ca-central-1" },
    identityPoolId: "ca-central-1:0741b2ec-53e5-454a-80d5-3c0419d3dc91",
    logins: {
      // Optional tokens, used for authenticated login.
    },
  }),
});

export function parseImgPath(folder: string): string {
  let path = folder.split("./img/").pop();

  if (!path) {
    console.log("invalid folder value in getImagesForService");
    return "";
  }

  return path;
}

export const getS3ImageNamesInFolder = async (folder: string) => {
  let path = parseImgPath(folder);

  s3ImgParams.Prefix = path;

  const command = new ListObjectsV2Command(s3ImgParams);
  const response = await s3client.send(command);

  let imgs: string[] = [];
  response.Contents?.forEach((s3Img) => {
    if (s3Img.Key) {
      imgs.push(s3Img.Key);
    }
  });

  return imgs;
};

export const asStream = (response: GetObjectCommandOutput) => {
  return response.Body as ReadableStream;
};

export const asBlob = async (response: GetObjectCommandOutput) => {
  return await new Response(asStream(response)).blob();
};

export const asString = async (response: GetObjectCommandOutput) => {
  return await new Response(asStream(response)).text();
};

export const getS3ImagesInFolder = async (folder: string) => {
  //let path = parseImgPath(folder);

  let imageRequestParams = { ...s3ImgParams, Prefix: folder + "/" };

  const command = new ListObjectsV2Command(imageRequestParams);
  const response = await s3client.send(command);

  let imgs: string[] = [];
  response.Contents?.forEach((s3Img) => {
    if (s3Img.Key) {
      imgs.push(s3Img.Key);
    }
  });

  return imgs;
};
/*
export const getS3ImagesInFolder = async (folder: string) => {
  let path = parseImgPath(folder);

  s3ImgParams.Prefix = path;

  const command = new ListObjectsV2Command(s3ImgParams);
  const response = await s3client.send(command);

  console.log(response);
  let imgs: any[] = [];
  response.Contents?.forEach(async (s3Img) => {
    let getObjCommand = new GetObjectCommand({
      Bucket: "townish-serviceimages-dev",
      Key: s3Img.Key,
    });
    let getObjResponse = await s3client.send(getObjCommand);

    console.log(getObjResponse.Body!.transformToString("utf-8"));
    imgs.push(getObjResponse.Body!.transformToString("utf-8"), getImageNameOnly(s3Img.Key!));
  });

  return imgs;
};
*/

export const getS3SingleImageAsBlob = async (S3ImgKey: string) => {
  let getObjCommand = new GetObjectCommand({
    Bucket: "townish-serviceimages-dev",
    Key: S3ImgKey,
  });
  let getObjResponse = await s3client.send(getObjCommand);
  let blobResponse = await asBlob(getObjResponse);

  return blobResponse;
};

export const uploadFile = async (props: { imgPath: string; file: File }) => {
  const putObjectParams = {
    Body: props.file,
    Bucket: s3ImgParams.Bucket,
    Key: props.imgPath + "/" + props.file.name,
  };

  const authSession = await fetchAuthSession();
  const credentials = authSession.credentials;

  const s3ClientConfigWithCreds: S3ClientConfigType = {
    region: "ca-central-1",
    credentials: credentials,
  };

  const s3ClientWithCreds = new S3Client(s3ClientConfigWithCreds);
  const upload = new PutObjectCommand(putObjectParams);

  try {
    await s3ClientWithCreds.send(upload);
  } catch (err) {
    console.log(err);
  }
};

export const deleteS3Image = async (S3ImgKey: string) => {
  let deleteObjCommand = new DeleteObjectCommand({
    Bucket: "townish-serviceimages-dev",
    Key: S3ImgKey,
  });

  let deleteObjResponse = await s3client.send(deleteObjCommand);

  //console.log(deleteObjResponse);
};

export async function saveVendorToDB(vendor: CreateVendorInput, setVendor: React.Dispatch<React.SetStateAction<CreateVendorInput | undefined>>, update: boolean) {
  try {
    await amplifyClient.graphql({
      query: update ? updateVendor : createVendor,
      variables: {
        input: {
          ...vendor,
        },
      },
    });

    setVendor(vendor);
    //setBookables(response.data.listBookables.items as Bookable[]);
  } catch (err) {
    console.log(err);
  }
}

export async function fetchBookableByID(bookableID: string): Promise<CreateBookableInput | undefined> {
  let bkbl;

  try {
    let response = await amplifyClient.graphql({
      query: getBookable,
      variables: {
        bookableID: bookableID,
      },
    });
    if (response.data.getBookable) {
      bkbl = response.data.getBookable;
    }
  } catch (err) {
    console.log(err);
  }

  return bkbl;
}

export async function fetchBookableCategoriesByBookableID(bookableID: string): Promise<CreateBookablesCategoriesInput[]> {
  let bkblCats: CreateBookablesCategoriesInput[] = [];

  try {
    let response = (await amplifyClient.graphql({
      query: bookablesCategoriesByBookableBookableID,
      variables: {
        bookableBookableID: bookableID,
      },
    })) as {
      data: {
        bookablesCategoriesByBookableBookableID: {
          items: CreateBookablesCategoriesInput[];
        };
      };
    };

    if (response.data.bookablesCategoriesByBookableBookableID.items) {
      bkblCats = response.data.bookablesCategoriesByBookableBookableID.items;
    }
  } catch (err) {
    console.log(err);
  }

  return bkblCats;
}

export async function fetchBookablesForVendor(vendorID: string) {
  let response;

  if (vendorID) {
    try {
      response = await amplifyClient.graphql({
        query: listBookables,
        variables: {
          filter: { vendorBookablesVendorID: { eq: vendorID } },
        },
      });
      return response.data.listBookables.items as Bookable[];
    } catch (err) {
      console.log(err);
    }
  }
}

export async function saveBookableToDB(bookable: CreateBookableInput, update: boolean) {
  try {
    await amplifyClient.graphql({
      query: update ? updateBookable : createBookable,
      variables: {
        input: {
          ...bookable,
        },
      },
    });
  } catch (err) {
    console.log(err);
  }
}

export async function saveBookableCategoriesToDB(bookablesCategories: CreateBookablesCategoriesInput[], update: boolean) {
  let listResponse = await amplifyClient.graphql({
    query: listBookablesCategories,
    variables: {
      filter: { bookableBookableID: { eq: bookablesCategories[0].bookableBookableID } },
    },
  });

  listResponse.data.listBookablesCategories.items.forEach(async (bci: BookablesCategories) => {
    await amplifyClient.graphql({
      query: deleteBookablesCategories,
      variables: {
        input: { id: bci.id },
      },
    });
  });
  /*


      */

  bookablesCategories.forEach(async (bookableCategory) => {
    try {
      await amplifyClient.graphql({
        query: createBookablesCategories,
        variables: {
          input: {
            ...bookableCategory,
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  });
}

export function StarIcons(props: { rating: number }) {
  let icons: ReactElement[] = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= props.rating) icons.push(<StarIcon key={"star" + i} style={{ color: "gold" }} />);
    else if (props.rating % 1 !== 0 && i - 1 === Math.floor(props.rating)) icons.push(<StarHalfIcon key={"star" + i} style={{ color: "gold" }} />);
    else if (i > props.rating) icons.push(<StarOutlineIcon key={"starOutline" + i} style={{ color: "gray" }} />);
  }

  return <div style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>{icons}</div>;
}
