import { SteppedForm, formDef } from "../SteppedForm";
import { Nav } from "../nav";

export const bookableForm: formDef = {
  title: "Create Bookable Option",
  instructions: "You can add multiple ways for customers to book your service, for example different durations or session types, which will each be shown on a 'Bookable detail' page.",
  formSteps: [
    {
      title: "Description",
      subtitle: "Help users find the right option for booking your service by giving it a catchy name and complete description.",
      inputs: [
        {
          friendly: "Categories",
          field: "bookableCategory",
          type: "subCategory",
          helpText: "Select all of the subcategories that fit the type of service you offer.",
        },
        {
          friendly: "Bookable Name",
          field: "bookableName",
          type: "text",
        },
        {
          friendly: "Bookable Subheading",
          field: "bookableSubheading",
          type: "text",
          helpText: "A short (50 - 100 character) tagline for this offering.",
        },
        {
          friendly: "Bookable Description",
          field: "bookableDescription",
          type: "textarea",
          helpText: "A longer description of the highlights of your service, ideally 350 - 500 characters in length.",
        },
      ],
    },
    {
      title: "Location",
      subtitle: "Indicate whether your service will be provided at a customer site, otherwise confirm the address.",
      inputs: [
        {
          friendly: "Street address",
          field: "address",
          type: "text",
          showIf: "!portableService",
        },
        {
          friendly: "City",
          field: "city",
          type: "text",
          showIf: "!portableService",
        },
        {
          friendly: "State / province",
          field: "state",
          type: "text",
          showIf: "!portableService",
        },
        {
          friendly: "ZIP / Postal code",
          field: "zipCode",
          type: "text",
          showIf: "!portableService",
        },
        {
          friendly: "Location",
          field: "bookableLocation",
          type: "location",
        },
      ],
    },
    {
      title: "Logistics",
      subtitle: "Enter the duration of this booking in hours or nights, as well as the min and max group size that you can accomodate.",
      inputs: [
        {
          friendly: "Duration",
          field: "bookableDuration",
          type: "hoursOrDays",
        },
        {
          friendly: "Minimum group size",
          field: "bookableMinOccupancy",
          type: "number",
        },
        {
          friendly: "Maximum group size",
          field: "bookableMaxOccupancy",
          type: "number",
        },
      ],
    },
    {
      title: "Media",
      subtitle:
        "Upload 3-8 pictures and link to hosted videos that highlight the best parts of your service. Click to select the main image which will be used for the thumbnail and main image areas. Your booking option will look best with landscape images approx. 1920 pixels wide x 1080 pixels in height.",
      inputs: [
        {
          friendly: "Image upload",
          field: "bookableImgPath",
          type: "image",
        },
        {
          friendly: "Link to video (optional)",
          field: "bookableVideoURL",
          type: "text",
          optional: true,
        },
      ],
    },
    {
      title: "Pricing",
      subtitle:
        "Enter the total pre-tax price that customers will pay to book this service. The Townish booking fee will be built into this price, and you'll see the specifics before saving this value.",
      inputs: [
        {
          friendly: "Price type",
          field: "bookablePriceType",
          type: "priceTypeBoolean",
        },
        {
          friendly: "Booking price",
          field: "bookablePrice",
          type: "price",
        },
      ],
    },
  ],
};

export function CreateBookable() {
  return (
    <>
      <section className="landing-section">
        <div className="landing-div">
          <div>
            <Nav title={"Create booking option"} />
          </div>
        </div>
      </section>
      <div style={{ padding: "2rem 1.5rem", width: "70%", maxWidth: "800px", display: "flex", flexDirection: "column", margin: "0 auto .25rem  auto" }}>
        <SteppedForm form={bookableForm} formType={"bookable"} />
      </div>
    </>
  );
}
