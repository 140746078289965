import { useParams } from "react-router-dom";
import { SteppedForm } from "../../components/SteppedForm";
import { bookableForm } from "../../components/CreateBookable";
import { Nav } from "../../components/nav";
import { useEffect, useState } from "react";
import { CreateBookableInput, CreateBookablesCategoriesInput } from "../../API";
import { fetchBookableByID, fetchBookableCategoriesByBookableID } from "../../Utils";

export function EditBookable() {
  let { bookableID } = useParams();
  const [bkbl, setBkbl] = useState<CreateBookableInput | undefined>();
  const [categories, setCategories] = useState<CreateBookablesCategoriesInput[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (bookableID) {
        const newBkbl = await fetchBookableByID(bookableID);
        setBkbl(newBkbl);

        const newCats = await fetchBookableCategoriesByBookableID(bookableID);
        setCategories(newCats);

        setLoading(false);
      }
    })();
  }, [bookableID]);

  return (
    <>
      <section className="landing-section">
        <div className="landing-div">
          <div>
            <Nav title={"Edit booking option" + (bkbl?.bookableName ? " - " + bkbl?.bookableName : "")} />
          </div>
        </div>
      </section>

      <div style={{ padding: "2rem 1.5rem", width: "70%", maxWidth: "800px", display: "flex", flexDirection: "column", margin: "0 auto .25rem  auto" }}>
        {loading && (
          <div className="landing-div">
            <h5 style={{ margin: 0 }}>Retrieving details...</h5>
          </div>
        )}
        {!loading && (
          <div className="landing-div">
            {/*<span className="desktop-body">Edit one or more elements of your booking option and click Save Changes on the last step, or click the Townish logo to go back to the dashboard.</span>*/}
            <SteppedForm form={bookableForm} formType={"bookable"} objToEdit={bkbl} objCategories={categories} skipInstructions={true} />
          </div>
        )}
      </div>
    </>
  );
}
